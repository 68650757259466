import * as React from 'react'

import '../../App.css';

import AuthContext from "../../context/AuthContext";

import { useGoogleLogin } from '@react-oauth/google';

const GoogleSocialAuth = () => {
    const { loginGoogleUser } = React.useContext(AuthContext);
    
    const responseGoogle = async(response: any) => {
        await loginGoogleUser(response.access_token);
      }

    const login = useGoogleLogin({
        onSuccess: token => responseGoogle(token),
        onError: error => console.log(error),
    });

    return (
        <div>
            <div id="customBtn" className="customGPlusSignIn" onClick={() => login()}>
                <span className="icon" ></span>
                <span className="buttonText">Google</span>
            </div>
        </div>
    )
}

export default GoogleSocialAuth