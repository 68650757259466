import * as React from 'react';

import ReactGA from 'react-ga4';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';

const RequestPasswordReset = () => {

    const [email, setEmail] = React.useState('');
    const [emailSent, setEmailSent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);
    
    const handleRequestPasswordReset = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/request-password-reset/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        });
        setLoading(false); 
        setEmail('');
        setEmailSent(true);
    }

    return (
        <div>
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            {loading ? <CircularProgress sx={{ marginTop: '20%'}} /> :
            <Paper elevation={3} sx={{ padding: '5%', marginTop: '10%'}}>
            {
                emailSent ?
                <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: '5%'}}>
                    Revisa tu correo para restablecer tu contraseña.
                    Contáctanos si no recibes el correo en los próximos minutos.
                </Typography>
                :
                <div>
                    <Typography variant="h5" component="h3">
                        Reestablece tu contraseña
                    </Typography>
                    <Typography component="p">
                        Ingresa tu correo electrónico y te enviaremos un enlace para reestablecer tu contraseña.
                    </Typography>
                    <TextField
                        id="email"
                        label="Correo electrónico"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleRequestPasswordReset}>
                        Enviar
                    </Button>
                </div>
            }
        </Paper>}
        </Box>
    </div>
    )
};

export default RequestPasswordReset;