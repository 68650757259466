
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import FeedbackNavigator from './Feedback/Navigator';
import FeedbackCase from './Feedback/Case';

import { CaseType } from '../types';

interface FeedbackSimulatorProps {
    examData: any
    playlists: any[]
    addPlaylist?: (name: string) => Promise<void>
    addQuestionToPlaylist?: (playlistId: number, questionId: number) => Promise<void>
    removeQuestionFromPlaylist?: (playlistId: number, questionId: number) => Promise<void>
    currentCase: number
    handleChangeCase: (newCase: number) => void
    handleFinishExam: () => void
    caseRef: React.RefObject<HTMLDivElement>
    drawerOpen: boolean
    timeElapsed: number
    correctCases: Set<string>
    correctQuestions: Set<string>
}

const FeedbackSimulator = (props: FeedbackSimulatorProps) => {
    return (
        <div>
            <Box sx={{ display:'flex', alignItems:'center', width:'100%', height:'100%'}}>
                <Box sx={{ width:'100%', height:'100%', display:'table'}}>
                    <Box sx={{ display:'table-row', height:'100%', width:'100%', flexGrow:1}}>
                        <Stack sx={{ display:'table-cell', alignItems:'left'}}>
                            <FeedbackCase examData={props.examData} 
                                                playlists={props.playlists}
                                                addPlaylist={props.addPlaylist}
                                                addQuestionToPlaylist={props.addQuestionToPlaylist}
                                                removeQuestionFromPlaylist={props.removeQuestionFromPlaylist}
                                                currentCase={props.currentCase} 
                                                handleChangeCase={props.handleChangeCase} 
                                                handleFinishExam={props.handleFinishExam}
                                                caseRef={props.caseRef}/>
                        </Stack>

                        <Slide direction="left" in={props.drawerOpen} mountOnEnter unmountOnExit timeout={500} style={{ display:'table-cell', width:'20%', height:'100%', flexGrow:1}}>
                            <Box>
                                <FeedbackNavigator examData={props.examData} 
                                                    correctCases={props.correctCases} 
                                                    correctQuestions={props.correctQuestions}
                                                    handleChangeCase={props.handleChangeCase} 
                                                    currentCase={props.currentCase}/>
                            </Box>
                        </Slide>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default FeedbackSimulator;