import * as React from 'react'
import dayjs from '../utils/dayjs'

import { subscriptionStatus } from '../utils/constants'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

interface SubscriptionDetailProps {
    status: string;
    current_period_end: string;
    showSubscriptionDetail: () => void;
}

const SubscriptionDetail = (props: SubscriptionDetailProps) => {
    return (
        <div>
            <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft: '2%',
                marginRight: '2%',
                marginTop: '5%',
                marginBottom: '4%'
            }}>
                <Box>
                    <Stack spacing={2}>
                        <Typography variant="h5" >
                            Subscripción
                        </Typography>
                        <Button variant="contained" color="primary" onClick={props.showSubscriptionDetail}>Detalles</Button>
                    </Stack>
                </Box>
                <Stack spacing={4}>
                    <div>
                        <Typography variant="h6" color="primary">Suscrito hasta: {
                            dayjs(props.current_period_end).format('DD/MM/YYYY')
                        }</Typography>
                    </div>
                    <div>
                        <Typography variant="h6" color="primary">Estado: {subscriptionStatus.get(props.status)}</Typography>
                    </div>
                </Stack>
            </Box>
        </div>
    )
}

export default SubscriptionDetail