
import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import MainCardTable from './MainCardTable';
import SimplePieChart from './SimplePieChart';
import SimpleKeyValueTable from './SimpleKeyValueTable';

import { difficulties, categories, specialties } from '../utils/constants';
import getColorGradient from '../utils/getColorGradient';
import SimpleLineChart from './SimpleLineChart';

import dayjs from '../utils/dayjs';


interface StatisticsProps {
    simulators: any;
}

const Statistics = (props: StatisticsProps) => {
  const simulators = props.simulators;

  const [showCorrect, setShowCorrect] = React.useState(true);
  const [difficultyGradesTable, setDifficultyGradesTable] = React.useState({} as any);
  const [categoryGradesTable, setCategoryGradesTable] = React.useState({} as any);
  const [specialtyGradesTable, setSpecialtyGradesTable] = React.useState({} as any);

  React.useEffect(() => {
    const getCorrectGrouped = (groupMap: any, groupName:string) => {
      const correctGrouped = {} as any;
      simulators.forEach((simulator: any) => {
        if (!simulator.active) {
          if (simulator.grades) { // Get the grade for the given groupName
            const groupStats = simulator.grades[groupName]
            groupStats.forEach((groupStat: any) => {
              const groupKey = groupMap.get(groupStat.key);
              const result = showCorrect ? groupStat.correct : groupStat.count - groupStat.correct;
              if (correctGrouped[groupKey]) {
                correctGrouped[groupKey] += result;
              } else {
                correctGrouped[groupKey] = result;
              }
          })} else {
            return;
          }
        }
      });
      return correctGrouped;
    }
    setDifficultyGradesTable(getCorrectGrouped(difficulties.map, 'difficulty'));
    setCategoryGradesTable(getCorrectGrouped(categories.map, 'category'));
    setSpecialtyGradesTable(getCorrectGrouped(specialties.map, 'specialty'));
  }, [simulators, showCorrect])


  if (simulators.length === 0) {
    return (
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'60vh'}}>
        <Typography variant='h5' sx={{ color: 'black'}}>
            No hay estadísticas disponibles
        </Typography>
      </Box>      
    )
  }

  const totalSimulators = simulators.length;

  const activeSimulators = []
  const inactiveSimulators = []

  simulators.forEach((simulator:any) => {
    if (simulator.active) {
      activeSimulators.push(simulator);
    } else {
      inactiveSimulators.push(simulator);
    }
  });

  const totalActiveSimulators = activeSimulators.length;
  const totalInactiveSimulators = totalSimulators - totalActiveSimulators;

  const averageGrade = simulators.reduce((totalGrade: number, simulator: any) => {
    if (!simulator.active) {
      if (simulator.grades) {
        return totalGrade + simulator.grades.score / simulator.grades.max_score;
      } else {
        return totalGrade;
      }
    } else {
      return totalGrade;
    }
  }, 0) / totalSimulators;

  const averageGradePercentage = averageGrade * 100;

  const simulatorStatusTable = [{
    'Total': totalSimulators,
    'Activos': totalActiveSimulators,
    'Inactivos': totalInactiveSimulators,
    'Calificación Promedio': `${averageGradePercentage.toFixed(0)}%`,
  }]

  const totalStudiedTime = simulators.reduce((totalTime: number, simulator: any) => {
    if (!simulator.active) {
      if (simulator.timeElapsedMilliseconds) {
        return totalTime + simulator.timeElapsedMilliseconds;
      } else {
        return totalTime;
      }
    } else {
      return totalTime;
    }
  }, 0);

  const averageStudiedTime = totalStudiedTime / totalSimulators;

  const totalStudiedTimeHours = Math.floor(totalStudiedTime / 3600000);
  const totalStudiedTimeMinutes = Math.floor((totalStudiedTime - totalStudiedTimeHours * 3600000) / 60000);

  const averageStudiedTimeHours = Math.floor(averageStudiedTime / 3600000);
  const averageStudiedTimeMinutes = Math.floor((averageStudiedTime - averageStudiedTimeHours * 3600000) / 60000);

  const dateWithMostHoursStudied = simulators.reduce((dateWithMostHoursStudied: any, simulator: any) => {
    if (!simulator.active) {
      if (simulator.timeElapsedMilliseconds) {
        const date = dayjs(simulator.dateCreated['$date']).format('DD/MM/YYYY');
        if (dateWithMostHoursStudied[date]) {
          dateWithMostHoursStudied[date] += simulator.timeElapsedMilliseconds;
        } else {
          dateWithMostHoursStudied[date] = simulator.timeElapsedMilliseconds;
        }
      }
    }
    return dateWithMostHoursStudied;
  }, {} as any);

  const preferredDayOfWeek = simulators.reduce((preferredDayOfWeek: any, simulator: any) => {
    if (!simulator.active) {
      if (simulator.timeElapsedMilliseconds) {
        const dayOfWeek = dayjs(simulator.dateCreated['$date']).format('dddd');
        if (preferredDayOfWeek[dayOfWeek]) {
          preferredDayOfWeek[dayOfWeek] += simulator.timeElapsedMilliseconds;
        } else {
          preferredDayOfWeek[dayOfWeek] = simulator.timeElapsedMilliseconds;
        }
      }
    }
    return preferredDayOfWeek;
  }, {} as any);

  const timeManagementTable = {
    'Horas totales de estudio': `${totalStudiedTimeHours.toFixed(0)}h ${totalStudiedTimeMinutes.toFixed(0)}m`,
    'Duración promedio de examen': `${averageStudiedTimeHours.toFixed(0)}h ${averageStudiedTimeMinutes.toFixed(0)}m`,
    'Fecha con más horas de estudio': Object.keys(dateWithMostHoursStudied).reduce((a, b) => dateWithMostHoursStudied[a] > dateWithMostHoursStudied[b] ? a : b),
    'Día de la semana preferido': Object.keys(preferredDayOfWeek).reduce((a, b) => preferredDayOfWeek[a] > preferredDayOfWeek[b] ? a : b),
  }

  const simulatorsByDate = {} as any;

  simulators.forEach((simulator:any) => {
    const date = dayjs(simulator.dateCreated['$date']).format('DD/MM/YYYY');
    if (!simulatorsByDate[date]) {
      simulatorsByDate[date] = [simulator];
    }
    simulatorsByDate[date].push(simulator);
  });

  const studiedHoursLabels = [] as any;
  const studiedHoursSeries = [] as any;

  // Reverse the order of the dates
  const dates = Object.keys(simulatorsByDate).reverse();

  dates.forEach((date) => {
    const studiedHours = simulatorsByDate[date].reduce((totalTime: number, simulator: any) => {
      if (simulator.timeElapsedMilliseconds) {
        return totalTime + simulator.timeElapsedMilliseconds;
      } else {
        return totalTime;
      }
    }, 0);
    studiedHoursLabels.push(date);
    studiedHoursSeries.push(studiedHours / 3600000).toFixed(2);
  });

  return (
    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%'}}>
      <Stack sx={{width:'100%'}} spacing='1rem'>

        <Stack direction='row' spacing='1rem' sx={{ width:'100%', display:'flex', justifyContent:'space-between'}}>
          <Box>
            <Typography variant='h6' sx={{ color: 'black', mb:'10%'}} textAlign='left'>
                Simuladores
            </Typography>
            <MainCardTable data={simulatorStatusTable} headerColor={'#F5F5F5'} />
          </Box>

          <Box>
            <Typography variant='h6' sx={{ color: 'black'}} textAlign='center'>
                Distribución de respuestas 
            </Typography>
            <Button onClick={() => setShowCorrect(!showCorrect)}>
              {showCorrect ? 'correctas' : 'incorrectas'}
            </Button>
            <Stack direction='row'>
              <Box>
                <SimplePieChart title='Dificultad' labels={Object.keys(difficultyGradesTable)} 
                                                   series={Object.values(difficultyGradesTable)}
                                                   colors={getColorGradient(Object.keys(difficultyGradesTable).length, 'rgb(153, 102, 255)')}/>
              </Box>
              <Box>
                <SimplePieChart title='Categoría' labels={Object.keys(categoryGradesTable)}
                                                  series={Object.values(categoryGradesTable)}
                                                  colors={getColorGradient(Object.keys(categoryGradesTable).length, 'rgb(23, 162, 184)')}/>
              </Box>
              <Box>
                <SimplePieChart title='Especialidad' labels={Object.keys(specialtyGradesTable)}
                                                     series={Object.values(specialtyGradesTable)}
                                                     colors={getColorGradient(Object.keys(specialtyGradesTable).length, 'rgb(255, 99, 132)')}/>
              </Box>
            </Stack>
          </Box>
          
        </Stack>

        <Stack direction='row' spacing='1rem' sx={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-around'}}>
          <Box>
            <Typography variant='h6' sx={{ color: 'black'}} textAlign='left'>
                Horas de estudio
            </Typography>
            <Box>
              <SimpleLineChart title='Tiempo de estudio' series={studiedHoursSeries} labels={studiedHoursLabels}/>
            </Box>
          </Box>
          <SimpleKeyValueTable data={timeManagementTable} headerColor={'#F5F5F5'} />
        </Stack>

      </Stack>
    </Box>
  )
}

export default Statistics;

