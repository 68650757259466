import * as React from 'react';
import '../App.css'

import ReactGA from 'react-ga4';

import { useNavigate, useParams } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

import useAxios from '../utils/useAxios';

import { summarizeDifficulties, summarizeCategories, summarizeSpecialties } from '../utils/reviewSummary';

import { PlaylistType } from '../types';

const Reviewer = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = React.useState(false);
    const [review, setReview] = React.useState<PlaylistType>({} as PlaylistType);
    const [focusedQuestion, setFocusedQuestion] = React.useState<number>(0);
    const [showExplanation, setShowExplanation] = React.useState<boolean>(false);

    const explanationRef = React.useRef<HTMLDivElement>(null);

    const [completed, setCompleted] = React.useState(false);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    React.useEffect(() => {
        const getReview = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`playlists/${id}`);
                const pl = response.data
                pl.questions = pl.questions.sort(() => Math.random() - 0.5).slice(0, 10);
                setReview(pl);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        if (initialized) {
            getReview();
            window.scrollTo(0, 0);
        }
    }, [id, initialized]);

    React.useEffect(() => {
        if (explanationRef.current && showExplanation) {
            explanationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [showExplanation]);

    React.useEffect(() => {
        const submitReview = async () => {
            try {
                const response = await axios.post('reviews/', {
                    playlist: review.id,
                    questions: review.questions
                });
            } catch (error) {
                console.log(error);
            }
        }
        if (completed) {submitReview()}
    }, [completed]);

    

    const handleSelectAnswer = (answerIndex: number) => {
        setReview({
            ...review,
            questions: review.questions.map((question, index) => {
                if (index === focusedQuestion && !showExplanation) {
                    return {
                        ...question,
                        answers: question.answers.map((answer: any, index: number) => {
                            if (index === answerIndex) {
                                return {
                                    ...answer,
                                    selected: true
                                }
                            }
                            return {
                                ...answer,
                                selected: false
                            }
                        })
                    }
                }
                return question;
            }
        )})
        setShowExplanation(true);
    }

    const handleNextQuestion = () => {
        if (focusedQuestion < review.questions.length - 1) {
            setShowExplanation(false);
            setFocusedQuestion(focusedQuestion + 1);
        } else {
            setCompleted(true);
        }
        window.scrollTo(0, 0);
    }

    const stats = () => {
        const correct = review.questions.filter((question) => {
            return question.answers.filter((answer: any) => {
                return answer.selected && answer.correct;
            }).length > 0;
        }).length;
        const incorrect = review.questions.filter((question) => {
            return question.answers.filter((answer: any) => {
                return answer.selected && !answer.correct;
            }).length > 0;
        }).length;
        return {
            correct,
            incorrect
        }
    }
  

    return (
        <div className='reviewer'>
            {!completed ? (
                <Stack>
                {
                    review.questions?.length > 0 && (
                
                    <Paper elevation={3} sx={{ p: 2, m: 2 }}>
                        <Typography variant='h6' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                            {review.questions[focusedQuestion].case.prompt}
                        </Typography>
                    </Paper>
                    )
                }
                {
                    review.questions?.length > 0 && (
                    <Paper elevation={3} sx={{ p: 2, m: 2 }}>
                        <Stack>
                            <Typography variant='h4' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                {review.questions[focusedQuestion].prompt}
                            </Typography>
                            <div className='reviewer-wrapper'>
                                {review.questions[focusedQuestion].answers.map((answer: any, index: number) => { 
                                return (
                                        <Paper key={index} elevation={3} sx={{ p: 2, m: 2,  cursor: 'pointer', '&:hover': { backgroundColor: answer.selected ? answer.correct ? '#B2DFDB' : '#ff7373' : '#E0E0E0' }, backgroundColor: answer.selected ?  answer.correct ? '#B2DFDB' : '#ff7373' : '#F5F5F5' }}
                                            onClick={() => handleSelectAnswer(index)}
                                        >
                                            <Typography variant='h5' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                                {answer.prompt}
                                            </Typography>
                                        </Paper>
                                    )
                                })
                                }
                            </div>
                        </Stack>
                    </Paper>
                    )
                }
                {
                    review.questions?.length === 0 && (
                        <Paper elevation={3} sx={{ p: 2, m: 2 }}>
                            <Typography variant='h4' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                No hay preguntas disponibles
                            </Typography>
                            <Button onClick={() => {
                                navigate('/home');
                            }}>
                                Volver
                            </Button>
                        </Paper>
                    )
                }
                {showExplanation && (
                    <Paper elevation={3} sx={{ p: 2, m: 2 }}
                        ref={explanationRef}
                    >
                        <Stack>
                            <Typography variant='h6' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                {review.questions && review.questions[focusedQuestion].case.explanation}
                            </Typography>
                            <Button variant='contained' onClick={handleNextQuestion}>
                                Siguiente
                            </Button>
                        </Stack>
                    </Paper>
                )}
            </Stack>) : 
            (
                <Paper elevation={3} sx={{ p: 1.2, m: 2 }}>
                    <Typography variant='h4' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                        Revisión terminada
                    </Typography>
                    <Stack>
                        <Typography variant='h6' gutterBottom sx={{ fontFamily: 'Playfair Display', color: '#03a832'}}>
                            Correctas: {stats().correct}
                        </Typography>
                        <Typography variant='h6' gutterBottom sx={{ fontFamily: 'Playfair Display', color: '#ff0303'}}>
                            Incorrectas: {stats().incorrect}
                        </Typography>
                    </Stack>
                    <hr />
                    <Typography variant='h5' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                        Dificultades
                    </Typography>
                    <Stack>
                        {summarizeDifficulties(review.questions).series.map((difficulty, index) => {
                            return (
                                <Typography key={index} variant='h6' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                    {summarizeDifficulties(review.questions).labels[index] + ': ' + difficulty}
                                </Typography>
                            )
                        })}
                    </Stack>
                    <hr />
                    <Typography variant='h5' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                        Categorías
                    </Typography>
                    <Stack>
                        {summarizeCategories(review.questions).series.map((category, index) => {
                            return (
                                <Typography key={index} variant='h6' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                    {summarizeCategories(review.questions).labels[index] + ': ' + category}
                                </Typography>
                            )
                        })}
                    </Stack>
                    <hr />
                    <Typography variant='h5' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                        Especialidades
                    </Typography>
                    <Stack>
                        {summarizeSpecialties(review.questions).series.map((specialty, index) => {
                            return (
                                <Typography key={index} variant='h6' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                    {summarizeSpecialties(review.questions).labels[index] + ': ' + specialty}
                                </Typography>
                            )
                        })}
                    </Stack>
                    <hr />
                    <Stack spacing={1}>
                        <Button variant='contained' onClick={() => window.location.reload()}>Volver a revisar</Button>
                        <Button variant='contained' onClick={() => navigate('/home')}>Salir</Button>
                    </Stack>
                </Paper>
            )
        }
        </div>
    );
}

export default Reviewer;