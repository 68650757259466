import * as React from 'react';

import ReactGA from 'react-ga4';

import { useNavigate } from 'react-router-dom';

import PrimaryAppBar from '../components/PrimaryAppBar';
import SimulatorGoTo from '../components/SimulatorGoTo';
import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';

import useAxios from '../utils/useAxios';

const SimulatorMaker = () => {
    const navigate = useNavigate();
    const { axios, initialized } = useAxios();

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const handleCreateClassicSimulator = async () => {
      try {
          setLoading(true);
          const { data } = await axios.post('/exams/', {})
          setLoading(false);
          navigate(`/simulator/${data[0].examId}`)
        } catch (error) {
            setLoading(false);
            alert('El servicio no está disponible en estos momentos. Intente más tarde.')
        }
    }

    return (
      <div>
        <PrimaryAppBar />
        {
          loading ? <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', width:'100%', mt:'5%'}}><CircularProgress /></Box>
          :
          <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '90vh'}}>
            <SimulatorGoTo 
              title='Clásico' 
              description='Responde un ENARM de la más alta fidelidad al examen real'
              onClick={() => handleCreateClassicSimulator()}
            />
            <SimulatorGoTo
              title='Rápido'  
              description='Haz un ENARM con las preguntas que desees. Será más rápido y altamente similar.'
              onClick={() => navigate('/simulator-params')}
             />
            <SimulatorGoTo
              title='Personalizado'
              description='Crea tu propio ENARM con las preguntas que desees.'
              onClick={() => navigate('/simulator-custom')}
             />
        </Box>
        }
      </div>
    )
}

export default SimulatorMaker;