import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type CircularProgressWithLabelProps = {
    progress: number,
    label: string,
    backgroundColor?: string,
    size?: number,
};

const CircularProgressWithLabel = (props: CircularProgressWithLabelProps) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={100} size={props.size ? props.size : 80} sx={{ color: props.backgroundColor ? props.backgroundColor : '#F5F5F5' }} /> 
            <CircularProgress variant="determinate" value={Math.min(props.progress * 100, 100)} size={props.size ? props.size : 80}
                              sx={{ position: 'absolute' }} color="primary" />          
            <Box
                sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
            >
                <Typography
                variant="h6"
                component="div"
                color="text.primary"
                sx={{ textAlign: 'center' }}
                >{props.label}</Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;