import * as React from 'react'

import ReactGA from 'react-ga4'

import useAxios from '../utils/useAxios'

import PrimaryAppBar from '../components/PrimaryAppBar'
import ProfilePage from '../components/ProfilePage'

import UserContext from '../context/UserContext'

const Profile = () => {
    const { userinfo, profile } = React.useContext(UserContext);
    const { axios, initialized } = useAxios();

    const [simulators, setSimulators] = React.useState([]);

    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const handleCreatePortalSession = async () => {
        try {
            await axios.post('/create-portal-session/')
                .then((response: any) => {
                    window.location.href = response.data.url;
                }
            );
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        const getSimulators = async () => {
            const is_active = false;
            const progress = true;
            setLoading(true);
            try {
                const { data } = await axios.get('/exams', { params: { progress: progress} });
                setSimulators(data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        if (initialized) {
            getSimulators();
        }
    }, [initialized, profile]);  

    return (
        <div>
            <PrimaryAppBar />
            <ProfilePage 
                firstName={userinfo?.first_name} 
                lastName={userinfo?.last_name} 
                email={userinfo?.email}
                verified={profile?.verified} 
                stripe_subscription_status={profile?.stripe_subscription_status}
                stripe_subscription_current_period_end={profile?.stripe_subscription_current_period_end}
                showSubscriptionDetail={handleCreatePortalSession}
                simulators={simulators}
            />
        </div>
    )
}

export default Profile