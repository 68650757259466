import enarmplus from '../enarmplus.svg';

import PricingCard from './PricingCard';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

type ProductData = {
    title?: string;
    price?: number;
    description?: string;
    priceLookupKey?: string;
}

interface PricingProps {
    products: Array<ProductData>;
    handleProductSelection: (priceLookupKey: string) => void;
}

const Pricing = (props: PricingProps) => {

    return (
        <section className="pricing">
            <div className="pricing-content">
                <Stack spacing={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5%'}}>
                        <img src={enarmplus} alt="ENARM PLUS" style={{ width: '25%'}}/>
                    </Box>
                    <div>
                        <Typography variant='h4' sx={{ fontFamily: 'Roboto, sans-serif' }}>
                            Todas nuestras herramientas sin límite en un solo plan.
                        </Typography>
                    </div>
                    <Stack direction="row" spacing={4} sx={{ display: 'flex', justifyContent: 'center'}}>
                        {props.products.map((product, index) => (
                            <PricingCard
                                key={index}
                                title={product.title}
                                price={product.price}
                                description={product.description}
                                priceLookupKey={product.priceLookupKey}
                                discount={0}
                                handleProductSelection={props.handleProductSelection}
                            />
                        ))}
                    </Stack>
                </Stack>
            </div>
        </section>
    )
}

export default Pricing;