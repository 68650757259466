import * as React from 'react';

import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-content'>
                <div className='footer-content-left'>
                    <p>ENARM PLUS</p>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    color: 'white',
                    textDecoration: 'none',
                    outline: 0,

                }}>
                    <Link to='/terms-of-service'
                        style={{
                            color: 'white',
                            textDecoration: 'none',
                            outline: 0,
                        }}
                    >
                        Términos y condiciones
                    </Link>
                    <Link to='/privacy-policy'
                        style={{
                            color: 'white',
                            textDecoration: 'none',
                            outline: 0,
                        }}
                    >
                        Política de privacidad
                    </Link>
                </div>
                <div className='footer-content-right'>
                    <p>© 2022 TDSE Solutions for Education</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer