import * as React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Avatar from '@mui/material/Avatar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { blueGrey, deepPurple } from '@mui/material/colors';
import { useSearchParams } from 'react-router-dom';

import enarmpluslogo from '../enarmplus.svg';
import BoltIcon from '@mui/icons-material/Bolt';

import AuthContext from '../context/AuthContext';
import UserContext from '../context/UserContext';

const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: deepPurple[500],
      },
      secondary: {
        // This is green.A700 as hex.
        main: blueGrey[50],
      },
    },
  });

interface Props {
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type SimulatorAppBarProps = {
    remainingTime: number;
}


const SimulatorAppBar = (props:SimulatorAppBarProps) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const { user, logoutUser } = React.useContext(AuthContext);
    const { userinfo, setUserinfo, profile, setProfile } = React.useContext(UserContext);
    

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [queryParams, setQueryParams] = useSearchParams();

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            sx={{ paddingTop: "1rem" }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => {navigate("/profile"); handleMenuClose();}}>Perfil</MenuItem>
            <MenuItem onClick={() => {logoutUser(); handleMenuClose();}}>Cerrar sesión</MenuItem>
        </Menu>
    );

    const handleLogoClick = () => {
        const landingRoutes = ["/", "/login", "/register"];
        if (!landingRoutes.includes(location.pathname)) {
            navigate("/home");
        }
        else {
            navigate("/");
            window.location.reload();
        }
    }

    const remainingSeconds = Math.max(props.remainingTime, 0);
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    return (
        <ThemeProvider theme={theme}>
        <React.Fragment>
        <CssBaseline />
        <HideOnScroll>
            <AppBar color="secondary">
            <Toolbar>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                    <Stack sx={{display: "flex", alignItems: "center", justifyContent:'left', width:'40%'}} spacing={'10%'} direction="row">
                        <img onClick={handleLogoClick} src={enarmpluslogo} alt="Enarm Plus" style={{height: "4rem", width: "10rem"}} />
                        {
                            profile && profile?.stripe_subscription_status !== "active" && (
                                <Button endIcon={<BoltIcon />} sx={{ marginRight: 0 , marginLeft: 'auto',  borderRadius: 5 }} variant="outlined" color="primary" href="/subscribe">
                                    Suscribirse
                                </Button>
                            )    
                        }
                    </Stack>
                    
                    <Stack sx={{display: "flex", alignItems: "center", justifyContent:'right', width:'20%'}} spacing={'10%'} direction="row">
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            {minutes.toString().padStart(2, '0')}:{seconds.toFixed(0).toString().padStart(2, '0')}
                        </Typography>
                        <ToggleButtonGroup
                            sx={{ marginLeft: 'auto', marginRight: 0 }}
                            size="small">
                            <ToggleButton value="left" aria-label="left aligned"
                                          onClick={() => {
                                                setQueryParams({view: 'default'});
                                          }}>
                                <Typography variant="body2" sx={{ color: '#3f51b5' }}>
                                    Nuevo
                                </Typography>
                            </ToggleButton>
                            <ToggleButton value="center" aria-label="centered"
                                          onClick={() => {
                                                setQueryParams({view: 'classic'});
                                          }}>
                                <Typography variant="body2" sx={{ color: '#3f51b5' }}>
                                    Clásico
                                </Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {user ?
                            <Avatar 
                                onClick={handleProfileMenuOpen}
                                aria-controls={menuId}
                                sx={{ bgcolor: deepPurple[500], marginRight: 0, marginLeft: profile?.stripe_subscription_status === "active" ? 'auto' : '8%', cursor: 'pointer' }}>
                                    {userinfo?.email[0].toUpperCase()}
                            </Avatar>
                            :
                            <Button sx={{ marginRight: 0, marginLeft: 'auto', backgroundColor: '#3f51b5', borderRadius: 0 }} variant="contained" color="primary" href="/login">
                                Iniciar sesión
                            </Button>
                        }
                    </Stack>
                </div>
            </Toolbar>
            </AppBar>
        </HideOnScroll>
        {renderMenu}
        <Toolbar />
        </React.Fragment>
        </ThemeProvider>
    )
}

export default SimulatorAppBar;