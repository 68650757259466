import Box from '@mui/material/Box'
import Chart from 'react-apexcharts'

const SimplePieChart = (props) => {

    const rgbaToHex = (color) => {
        if (/^rgb/.test(color)) {
            const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
            // rgb to hex
            // eslint-disable-next-line no-bitwise
            let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
                .toString(16)
                .slice(1)}`;  
            const alpha = Number(rgba[3]);
            hex = hex + Math.round(alpha * 255).toString(16)
          return hex;
        }
        return color;
    };
    
    const colors =  props.colors.map(rgbaToHex)

    const options = {
        chart: {
            type: 'donut',
        },
        labels: props.labels,
        legend: {
            position: 'bottom',
        },
        colors: colors,
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            offsetY: -10
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            offsetY: 16,
                            formatter: function (val) {
                                return val
                            },
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            color: '#373d3f',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                            },
                        }
                    }
                }
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
    }

    return (
        <Box>
            <Chart options={options} series={props.series} type="donut" colors={colors} />
        </Box>
    )
}

export default SimplePieChart;