import * as React from 'react';
import enarmpluslogo from '../enarmplus.svg';

import { useNavigate } from 'react-router-dom';

import PrimaryAppBar from '../components/PrimaryAppBar';
import ExamResultsCard from '../components/ExamResultsCard';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import { ExamResultsType } from '../types'


const ExamResultsDemo = () => {
    const navigate = useNavigate();
    const [results, setResults] = React.useState<ExamResultsType>({} as ExamResultsType);

    React.useEffect(() => {
        console.log(JSON.parse(localStorage.getItem('resultsDemo') || '{}') as ExamResultsType);
        setResults(JSON.parse(localStorage.getItem('resultsDemo') || '{}') as ExamResultsType);
    }, []);

    const formatData = (data: any, mapping: any) => {
        const formattedData = {} as any
        for (const entry of data) {
            formattedData[mapping.get(entry.key)] = entry.correct + " / " + entry.count;
        }
        return formattedData
    }


    if (!results.overall) {
        return (
            <div>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:'15%', width:'100%'}}>
                    <CircularProgress />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <PrimaryAppBar />
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <img src={enarmpluslogo} alt="Enarm Plus" style={{width: "20rem"}} />
            </Box>
            <ExamResultsCard results={results} formatData={formatData}/>
            <Button onClick={() => navigate('/home')} variant='contained' size='large' sx={{ marginTop: '2%', backgroundColor: '#3f51b5', color: 'white', borderRadius: 0 }}>
                Regresar a la página principal
            </Button>
        </div>
    )
}

export default ExamResultsDemo;