import * as React from 'react';

import ReactGA from 'react-ga4';

import PrimaryAppBar from '../components/PrimaryAppBar';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import useAxios from '../utils/useAxios';

import { useNavigate } from 'react-router-dom';

const SimulatorParams = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();

    const [totalCases, setTotalCases] = React.useState(10)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const handleCreateSimulator = async () => {
        try {
            const simulatorParams = {
                "number_of_cases": totalCases
            }
            setLoading(true);
            const { data } = await axios.post('/exams/', simulatorParams)
            setLoading(false);
            navigate(`/simulator/${data[0].examId}`)
        } catch (error) {
            setLoading(false);
            alert('El servicio no está disponible en estos momentos. Intente más tarde.')
        }
    }

    return (
        loading ? <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', width:'100%', mt:'5%'}}><CircularProgress /></Box> 
        :
        <div>
            <PrimaryAppBar />
            {
                loading ? <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', width:'100%', mt:'5%'}}><CircularProgress /></Box>
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>
                    <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3%', height: '70%', width: '%' }}>
                        <Stack sx={{ display:'flex', justifyContent: 'center', width:'100%' }}>
                            <Typography variant='h3' sx={{ fontFamily: 'Playfair Display', color: 'black'}}>
                                ENARM Rápido
                            </Typography>
                            <Typography variant='h6' sx={{ fontFamily: 'Roboto Sans-Serif', color: 'black', mt:'2%'}}>
                                Crea un ENARM con la cantidad de casos clínicos que tú desees.
                            </Typography>
                            <Box sx={{ display:'flex', justifyContent: 'center', alignItems:'center', m:'2%'}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Número de casos</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={totalCases}
                                    label="Número de casos"
                                    onChange={(e) => setTotalCases(e.target.value as number)}
                                    >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Button color="secondary" variant='contained' sx={{fontFamily: 'Playfair Display', marginTop: '5%' }}
                                    onClick={() => handleCreateSimulator() }>
                                Crear
                            </Button>
                        </Stack>
                    </Paper>
                </Box>
            }
        </div>
    )
}

export default SimulatorParams

