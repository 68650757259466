import { difficulties, categories, specialties } from '../utils/constants';

export const summarizeDifficulties = (questions) => {
    const difficulties_ = new Map();
    questions.forEach((question) => {
        if (difficulties_.has(question.case.difficulty)) {
            difficulties_.set(question.case.difficulty, difficulties_.get(question.case.difficulty) + 1);
        } else {
            difficulties_.set(question.case.difficulty, 1);
        }
    });
    const series = [];
    const labels = [];
    difficulties_.forEach((value, key) => {
        series.push(value);
        labels.push(difficulties.map.get(key));
    });
    return {
        series: series,
        labels: labels,
    };
}

export const summarizeCategories = (questions) => {
    const categories_ = new Map();
    questions.forEach((question) => {
        if (categories_.has(question.case.category)) {
            categories_.set(question.case.category, categories_.get(question.case.category) + 1);
        } else {
            categories_.set(question.case.category, 1);
        }
    });
    const series = [];
    const labels = [];
    categories_.forEach((value, key) => {
        series.push(value);
        labels.push(categories.map.get(key));
    });
    return {
        series: series,
        labels: labels,
    };
}

export const summarizeSpecialties = (questions) => {
    const specialties_ = new Map();
    questions.forEach((question) => {
        if (specialties_.has(question.case.specialty)) {
            specialties_.set(question.case.specialty, specialties_.get(question.case.specialty) + 1);
        } else {
            specialties_.set(question.case.specialty, 1);
        }
    });
    const series = [];
    const labels = [];
    specialties_.forEach((value, key) => {
        series.push(value);
        labels.push(specialties.map.get(key));
    });
    return {
        series: series,
        labels: labels,
    };
}