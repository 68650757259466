import TwoWayMap from './TwoWayMap';


const low = process.env.REACT_APP_LOW_PRICE_KEY || '';
const medium = process.env.REACT_APP_MEDIUM_PRICE_KEY || '';
const high = process.env.REACT_APP_HIGH_PRICE_KEY || '';

const lowPrice = parseInt(process.env.REACT_APP_LOW_PRICE || '0');
const mediumPrice = parseInt(process.env.REACT_APP_MEDIUM_PRICE || '0');
const highPrice = parseInt(process.env.REACT_APP_HIGH_PRICE || '0');

const lowName = 'Mensual';
const mediumName = 'Timestral';
const highName = 'Semestral';

const lowDescription = 'Se cobra cada mes';
const mediumDescription = 'Se cobra cada tres meses';
const highDescription = 'Se cobra cada seis meses';

export const products = [
    { title: lowName, price: lowPrice, description: lowDescription, priceLookupKey: low },
    { title: mediumName, price: mediumPrice, description: mediumDescription, priceLookupKey: medium },
    { title: highName, price: highPrice, description: highDescription, priceLookupKey: high }
]

export const difficulties = new TwoWayMap(new Map([
    ['F', 'Fácil'],
    ['M', 'Medio'],
    ['D', 'Difícil'],
]));

export const categories = new TwoWayMap( new Map([
    ['SP', 'Salud Pública'],
    ['MF', 'Medicina Familiar'],
    ['U', 'Urgencias'],
]));

export const specialties = new TwoWayMap( new Map([
    ['MI', 'Medicina Interna'],
    ['GO', 'Ginecología'],
    ['P', 'Pediatría'],
    ['C', 'Cirugía'],
]));

export const english = new TwoWayMap( new Map([
    [true, 'Sí'],
    [false, 'No'],
]));


export const difficultyScores = new Map([
    ['F', 1],
    ['M', 2],
    ['D', 3],
]);


export const subscriptionStatus = new Map([
    ['active', 'Activa'],
    ['past_due', 'Vencida'],
    ['canceled', 'Cancelada'],
    ['unpaid', 'No pagada'],
    ['incomplete', 'Incompleta'],
    ['incomplete_expired', 'Expirada'],
    ['trialing', 'Prueba'],
    ['paused', 'En pausa'],
]);

