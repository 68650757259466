import * as React from 'react';

import Box from '@mui/material/Box'

import Chart from 'react-apexcharts'

const SimpleLineChart = (props) => {

    const series = [
        {
            name: props.title,
            data: props.series,
        }
    ]

    const options = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
        },
        stroke: {
            curve: 'smooth',
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            show: false,
        },
        xaxis: {
            type: 'category',
            categories: props.labels,
        },
        yaxis: {
            labels: {
              formatter: function (value) {
                return (value);
              }
            },
          },
    }
    return (
        <Box sx={{ width: 500 }}>
            <Chart options={options} series={series} type="line"/>
        </Box>
    )
}

export default SimpleLineChart