import * as React from 'react';

import ReactGA from 'react-ga4';

import { useNavigate } from 'react-router-dom';
import useAxios from '../utils/useAxios'
import UserContext from '../context/UserContext';

import '../App.css'

import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PrimaryAppBar from '../components/PrimaryAppBar';
import GoToSimulator from '../components/GoToSimulator';
import GoToPlaylist from '../components/GoToPlaylist';
import Footer from '../components/Footer';

const Home = () => {
    const navigate = useNavigate();
    const { axios, initialized } = useAxios();
    const { profile } = React.useContext(UserContext);
    
    const [currentTab, setCurrentTab] = React.useState(
      parseInt(localStorage.getItem('currentHomeTabENARM') || '0')
    );

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setCurrentTab(newValue);
      localStorage.setItem('currentHomeTabENARM', newValue.toString());
    };

    const [simulators, setSimulators] = React.useState([]);
    const [playlists, setPlaylists] = React.useState<Array<any>>([]);
    const [reviews, setReviews] = React.useState<Array<any>>([]);

    const [loading1, setLoading1] = React.useState(true);
    const [loading2, setLoading2] = React.useState(false);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);
  
    React.useEffect(() => {
        const getLatestSimulators = async () => {
            const is_active = true;
            const progress = true;
            setLoading1(true);
            try {
                const { data } = await axios.get('/exams', { params: { is_active: is_active, progress: progress} });
                setSimulators(data);
                setLoading1(false);
            } catch (error) {
                console.log(error);
            }
        }
        if (initialized) {
            getLatestSimulators();
        }
    }, [initialized, profile]);  

      React.useEffect(() => {
        const getPlaylists = async () => {
            setLoading2(true);
            try {
                const response = await axios.get('playlists');
                setPlaylists(response.data);
                setLoading2(false);
            } catch (error) {
                setLoading2(false);
            }
        }
        const getReviews = async () => {
            try {
                const response = await axios.get('reviews');
                setReviews(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        if (initialized) {
            getPlaylists();
            getReviews();
        }
    }, [initialized]);

    const createPlaylist = async (name: string) => {
        if (!name) {
            alert('Por favor ingrese un nombre para la review');
            return;
        }
        if (playlists.some(playlist => playlist.name === name)) {
            alert('Ya existe una playlist con ese nombre');
            return;
        }
        try {
            const response = await axios.post('playlists/', {name});
            const playlist: any[] = response.data;
            setPlaylists([playlist, ...playlists]);
        } catch (error) {
            console.log(error);
        }
    }

    const promptBasedOnSubscriptionStatus = () => {
      if (profile?.stripe_subscription_status === 'active') {
        return {prompt: 'Nueva simulación', action: () => navigate('/simulator-maker')};
      } else {
        return {prompt: 'Resuelve un simulador de prueba', action: () => navigate('/simulator-demo')};
      }
    }

    const tabsContent = [
      <GoToSimulator
        loading={loading1}
        simulators={simulators}
        actionPrompt={promptBasedOnSubscriptionStatus().prompt}
        action={promptBasedOnSubscriptionStatus().action}
        showDemo={profile?.stripe_subscription_status !== 'active'}
        individualItemAction={ {actionMessage: 'Reanudar', 
                                action: (id: number) => navigate(`/simulator/${id}`)} } 
      />, 
      <GoToPlaylist
        playlists={playlists}
        reviews={reviews}
        loading={loading2}
        createPlaylist={createPlaylist}
        details={(id: number) => navigate(`/playlists/${id}`)}
        go={(id: number) => navigate(`/reviewer/${id}`)}
        showDemo={profile?.stripe_subscription_status !== 'active'}
      />
    ];

    return (
      <div>
        <PrimaryAppBar />
        <Stack className="simulators" sx={{height:'90vh'}}>
          <Tabs value={currentTab} onChange={handleTabChange} sx={{marginTop:'1%'}}>
              <Tab label="Simuladores" sx={{color:'#fff', '&.Mui-selected':{color:'#fff'}}}/>
              <Tab label="Playlists" sx={{color:'#fff', '&.Mui-selected':{color:'#fff'}}}/>
          </Tabs>
          <div className="simulators-content">
            {tabsContent[currentTab]}
          </div>
        </Stack>
        <Footer />
      </div>
    )
}

export default Home;