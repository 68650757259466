
import * as React from 'react'
import { ExamResultsType } from '../types'


import enarmpluslogo from '../enarmplus.svg';

import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import { useParams, useNavigate } from 'react-router-dom';

import useAxios from '../utils/useAxios';

import PrimaryAppBar from '../components/PrimaryAppBar';
import ExamResultsCard from '../components/ExamResultsCard';


const ExamResults = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();
    const [results, setResults] = React.useState<ExamResultsType>({} as ExamResultsType);

    React.useEffect(() => {
        const fetchResults = async () => {
            try {
                const results = await axios.get(`exams-results/${id}/`);
                setResults(results.data);
            } catch (error) {
                console.log(error);
            }
        }
        if (initialized) fetchResults();
    }, [initialized]);

    const formatData = (data: any, mapping: any) => {
        const formattedData = {} as any
        for (const entry of data) {
            formattedData[mapping.get(entry.key)] = entry.correct + " / " + entry.count;
        }
        return formattedData
    }

    const { id } = useParams<{ id: string }>();

    if (!results.overall) {
        return (
            <div>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:'15%', width:'100%'}}>
                    <CircularProgress />
                </Box>
            </div>
        );
    }
     
    return (
        <div>
            <PrimaryAppBar />

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <img src={enarmpluslogo} alt="Enarm Plus" style={{width: "20rem"}} />
            </Box>
            <ExamResultsCard results={results} formatData={formatData} />

            <Stack sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <Button onClick={() => navigate(`/feedback/${id}`)} variant='outlined' size='large' sx={{ marginTop: '1%', borderRadius: '1rem' }}>
                    Revisión
                </Button>

                <Button onClick={() => navigate('/home')} variant='contained' size='large' sx={{ marginTop: '1%', backgroundColor: '#3f51b5', color: 'white', borderRadius: 0 }}>
                    Regresar a la página principal
                </Button>
            </Stack>

        </div>
    )
}

export default ExamResults
