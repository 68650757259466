import * as React from 'react';

import { categories, difficulties, specialties } from '../utils/constants';

import SimpleKeyValueTable from './SimpleKeyValueTable';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ExamResultsType } from '../types';

interface ExamResultsCardProps {
    results: ExamResultsType;
    formatData: (data: any, mapping: any) => any;
}

const correctColor = '#4CBB17';

const ExamResultsCard = (props: ExamResultsCardProps) => {
    return (
        <div>
            <Stack sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        Aciertos
                    </Typography>
                </Box>
                <Box sx={{display:'inline-flex', width:'30%', height:'1.8rem', borderRadius:'1rem', backgroundColor:'grey.300', position:'relative', mt:'1rem', mb:'1rem'}}>
                    <Box sx={{width:`${100 * props.results.overall[0].correct / props.results.overall[0].count}%`, 
                              height:'1.8rem', borderRadius:'1rem', backgroundColor: correctColor}}>    
                    </Box>
                    <Box sx={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center',
                              width:'100%', height:'1.8rem'}}>
                        <Typography variant="h5" component="h2" align="center" sx={{color:'white'}}>
                                {props.results.overall[0].correct} / {props.results.overall[0].count}
                        </Typography>
                    </Box>
                </Box>
            </Stack>

            <Stack direction = "row" spacing={5} sx={{display:'flex', alignItems:'center', justifyContent:'center', mt:'2%', width:'100%'}}>

                <Stack spacing={2} sx={{display:'flex', justifyContent:'center', width:'25%'}}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Área
                    </Typography>
                    <Box sx={{borderColor:'grey.300', borderRadius:'1rem'}}>
                        <SimpleKeyValueTable data={props.formatData(props.results.category, categories.map)} />
                    </Box>
                </Stack>

                <Stack spacing={2} sx={{display:'flex', justifyContent:'center', width:'25%'}}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Especialidad
                    </Typography>
                    <Box sx={{borderColor:'grey.300', borderRadius:'1rem'}}>
                        <SimpleKeyValueTable data={props.formatData(props.results.specialty, specialties.map)} />
                    </Box>
                </Stack>

                <Stack spacing={2} sx={{display:'flex', justifyContent:'center', width:'25%'}}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Dificultad
                    </Typography>
                    <Box sx={{borderColor:'grey.300', borderRadius:'1rem'}}>
                        <SimpleKeyValueTable data={props.formatData(props.results.difficulty, difficulties.map)} />
                    </Box>
                </Stack>

            </Stack>

            <Stack direction = "row" spacing={2} sx={{display:'flex', alignItems:'center', justifyContent:'center', mt:'2%', width:'100%'}}>

                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <Typography variant="h5" component="h3" align='center'>
                        Calificación ENARM
                    </Typography>
                </Box>
                <Stack direction='row' spacing='5%' sx={{display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'1rem', border:'1px solid grey', p:'1rem'}}>
                    <Typography variant="h5" align='center' sx={{color:'success.main', fontWeight:'bold'}}>
                        {props.results.score.toFixed(2)}
                    </Typography>
                    <Typography variant="h6" component="h3" align='center' sx={{color:'success.main', fontWeight:'bold'}}>
                        ({ (props.results.score /  props.results.max_score * 100).toFixed(0) }%)
                    </Typography>
                </Stack>
            </Stack>
        </div>
    )
}

export default ExamResultsCard;