import axios from "axios";
import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

type AuthContextData = {
    user: any;
    setUser: any;
    authTokens: any;
    setAuthTokens: any;
    registerUser: any;
    loginUser: any;
    loginGoogleUser: any;
    logoutUser: any;
}

const baseURL = process.env.REACT_APP_BASE_URL;

const AuthContext = createContext({} as AuthContextData);

export default AuthContext;
export const AuthProvider = ({ children }: {children: any}) => {
    const [authTokens, setAuthTokens] = useState(() => {
      if (typeof window !== "undefined") {
        return localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens") || '{}')
        : null;
      }
    }
    );
    const [user, setUser] = useState(() => {
      if (typeof window !== "undefined") {
        const authTokens = localStorage.getItem("authTokens")
        return authTokens
        ? jwt_decode(JSON.parse(localStorage.getItem("authTokens") || '{}').access)
        : null;
      }
    }
    );  
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
  
    const loginUser = async (username: string, password: string, lookup_key: string, loading: boolean, setLoading: any) => {
      setLoading(true);
      const response = await fetch(`${baseURL}token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: username,
          password
        })
      });
      const data = await response.json();
  
      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        //Profile
        if (lookup_key) {
          const axiosTemp = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            headers: { Authorization: `Bearer ${data.access}` }
          });
          await axiosTemp.post('/create-checkout-session/', {
            lookup_key: lookup_key
          }).then((response) => {
            window.location.href = response.data.url;
          });
        } else {
          navigate("/home");
        }
      } else {
        alert("Something went wrong!");
      }
      setLoading(false);
    };

    const loginGoogleUser = async (token: string) => {
      const response = await fetch(`${baseURL}rest-auth/google/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          access_token: token,
        })
      });

      const data = await response.json();
      const tokens = {access: data.access_token, refresh: data.refresh_token}

      if (response.status === 200) {
        setAuthTokens(tokens);
        setUser(jwt_decode(tokens.access));
        localStorage.setItem("authTokens", JSON.stringify(tokens));
        navigate("/home");
      } else {
        return 2;
      }
    };
    
    const registerUser = async (username: string, firstName: string, lastName: string,  password: string, confirmPassword: string, lookup_key: string, loading: boolean, setLoading: any) => {
      setLoading(true);
      const response = await fetch(`${baseURL}register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          email: username,
          name: firstName,
          surname: lastName,
          password,
          confirm_password: confirmPassword
        })
      });
      if (response.status === 201) {
        const data = await response.json();
        setAuthTokens(data.auth_token);
        setUser(jwt_decode(data.auth_token.access));
        localStorage.setItem("authTokens", JSON.stringify(data.auth_token));
        if (lookup_key) {
          const axiosTemp = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            headers: { Authorization: `Bearer ${data.auth_token.access}` }
          });
          await axiosTemp.post('/create-checkout-session/', {
            lookup_key: lookup_key
          }).then((response) => {
            window.location.href = response.data.url;
          });
        } else {
          navigate("/home");
        }
      } else {
        const data = await response.json();
        let errors = "";
        for (const key in data) {
          errors += data[key] + "\n";
        }
        alert(errors);
      }
      setLoading(false);
    };

    const logoutUser = () => {
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("authTokens");
      navigate("/");
    };
  
    const contextData = {
      user,
      setUser,
      authTokens,
      setAuthTokens,
      registerUser,
      loginUser,
      loginGoogleUser,
      logoutUser
    };
  
    useEffect(() => {
      if (authTokens) {
        authTokens.access && setUser(jwt_decode(authTokens.access));
      }
      setLoading(false);
    }, [authTokens, loading]);
  
    return (
      <AuthContext.Provider value={contextData}>
        {loading ? null : children}
      </AuthContext.Provider>
    );
  };