import * as React from 'react';

import Doodle from '../utils/Doodle';
import ExamProgressBar from './ExamProgressBar';
import TimeRemaining from './TimeRemaining';
import NoExams from './svgs/NoExams';

import '../App.css'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Virtuoso } from 'react-virtuoso';

import dayjs from '../utils/dayjs';
import SimulatorListItem from './SimulatorListItem';

interface GoToSimulatorProps {
    loading: boolean;
    simulators: any[];
    actionPrompt: string;
    action: () => void;
    individualItemAction: { actionMessage: string, action: (id: number) => void };
    showDemo: boolean;
}


const GoToSimulator = (props: GoToSimulatorProps) => {

    const [showAll, setShowAll] = React.useState(false);
    
    return (
        <section className='simulators'>
            <div className='simulators-content'>    
                <Stack sx={{ width: '100%'}}>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <Box sx={{ color: 'white', display:'flex', justifyContent:'space-between', width:'72%' }}>
                            <Typography variant='h3' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                Simuladores
                            </Typography>
                            <Box sx={{display:'flex', alignItems:'center'}}>                            
                                <Button variant='contained' sx={{fontFamily: 'Playfair Display'}} onClick={props.action}>
                                    {props.actionPrompt}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        {props.loading ? 
                            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:'15%', width:'100%'}}>
                                <CircularProgress />
                            </Box>
                        :
                            <Box>
                                {props.simulators?.length === 0 ?
                                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                                        <Box sx={{ width: '40%', height: '40%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                                            <NoExams />
                                        </Box>
                                        <Typography variant='h5' sx={{ fontFamily: 'Roboto Sans-serif', textAlign:'center', color: 'white'}}>
                                            No tienes simuladores activos.
                                        </Typography>
                                    </Box>
                                :
                                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                                    <Virtuoso
                                        style={{ height: '60vh', width:'80%', display:'flex', alignItems:'center', justifyContent:'center'}}
                                        data={showAll ? [...props.simulators, {isButton: true, buttonMessage: 'Ver menos', buttonAction: () => setShowAll(false)}]
                                                      : [...props.simulators.slice(0,2), {isButton: true, buttonMessage: 'Ver todos', buttonAction: () => setShowAll(true)}]}
                                        itemContent={(index, simulator:any) => (
                                            simulator.isButton ?
                                                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%', pb:'2%'}}>
                                                    <Button variant='contained' sx={{fontFamily: 'Playfair Display'}} onClick={simulator.buttonAction}>
                                                        {simulator.buttonMessage}
                                                    </Button>

                                                </Box>
                                            :
                                            <Box key={index}>
                                                <SimulatorListItem simulator={simulator} individualItemAction={props.individualItemAction} />
                                            </Box>
                                        )}
                                    />
                                </Box>
                                }
                                
                            </Box>
                        }
                    </Box>
                </Stack>
            </div>
        </section>
    );
}


export default GoToSimulator;