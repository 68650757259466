import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthProvider } from './context/AuthContext';
import { UserProvider } from './context/UserContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ProtectedRoute, ContributorRoute } from './utils/RouteProtection';

import Landing from './pages/Landing';
import Subscribe from './pages/Subscribe';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register'
import Success from './pages/Success';
import Cases from './pages/Cases';
import UpdateCase from './pages/UpdateCase';
import CreateCase from './pages/CreateCase';
import SimulatorMaker from './pages/SimulatorMaker';
import SimulatorParams from './pages/SimulatorParams';
import SimulatorCustom from './pages/SimulatorCustom';
import Simulator from './pages/Simulator';
import SimulatorDemo from './pages/SimulatorDemo';
import ExamResults from './pages/ExamResults';
import ExamResultsDemo from './pages/ExamResultsDemo';
import ExamFeedback from './pages/ExamFeedback';
import ReviewSummary from './pages/ReviewSummary';
import Reviewer from './pages/Reviewer';
import Verify from './pages/Verify';
import Validating from './pages/Validating';
import RequestPasswordReset from './pages/RequestPasswordReset';
import PasswordReset from './pages/PasswordReset';
import Profile from './pages/Profile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const App = () => {
  return (
    <div className="App">
        <Router>
          <AuthProvider>
            <GoogleOAuthProvider clientId={googleClientId}>
            <UserProvider>
            <Routes>
              <Route path="" element={<Landing />} />
              <Route path="/subscribe" element={<ProtectedRoute><Subscribe /></ProtectedRoute>} />
              <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route path="/simulator-maker" element={<ProtectedRoute><SimulatorMaker /></ProtectedRoute>} />
              <Route path="/simulator-params" element={<ProtectedRoute><SimulatorParams /></ProtectedRoute>} />
              <Route path="/simulator-custom" element={<ProtectedRoute><SimulatorCustom /></ProtectedRoute>} />
              <Route path="/simulator/:id" element={<ProtectedRoute><Simulator /></ProtectedRoute>} />
              <Route path="/simulator-demo" element={<ProtectedRoute><SimulatorDemo /></ProtectedRoute>} />
              <Route path="/results/:id" element={<ProtectedRoute><ExamResults /></ProtectedRoute>} />
              <Route path="/results-demo" element={<ProtectedRoute><ExamResultsDemo /></ProtectedRoute>} />
              <Route path="/feedback/:id" element={<ProtectedRoute><ExamFeedback /></ProtectedRoute>} />
              <Route path="/login" element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="/cases" element={<ContributorRoute><Cases /></ContributorRoute>} />
              <Route path="/cases/:id" element={<ContributorRoute><UpdateCase /></ContributorRoute>} />
              <Route path="/create-case" element={<ContributorRoute><CreateCase /></ContributorRoute>} />
              <Route path="/playlists/:id" element={<ProtectedRoute><ReviewSummary /></ProtectedRoute>} />
              <Route path="/reviewer/:id" element={<ProtectedRoute><Reviewer /></ProtectedRoute>} />
              <Route path="/verify/:uid/:token" element={<Validating />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/success" element={<ProtectedRoute><Success /></ProtectedRoute>} />
              <Route path="/request-password-reset" element={<RequestPasswordReset />} />
              <Route element={<PasswordReset />} path="/password-reset/:uid/:token" />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>
            </UserProvider>
            </GoogleOAuthProvider>
          </AuthProvider>
        </Router>
    </div>
  );
}

export default App;
