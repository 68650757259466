import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import Doodle from '../utils/Doodle';
import ExamProgressBar from './ExamProgressBar';
import TimeRemaining from './TimeRemaining';
import SimulatorScore from './SimulatorScore';
import NoExams from './svgs/NoExams';

import dayjs from '../utils/dayjs';


interface SimulatorListItemProps {
    simulator: any;
    individualItemAction: { actionMessage: string, action: (id: number) => void };
}

const SimulatorListItem = (props: SimulatorListItemProps) => {
  const simulator = props.simulator;
  return (
    <Paper elevation={3} sx={{ width: '80%', height: '25%', marginLeft: '10%', marginRight: 'auto', mt:'2%', mb:'2%', backgroundColor: '#F5F5F5'}}>
      <Stack direction="row" spacing={8} sx={{ height: '100%', alignItems: 'center', p:'2%'}}>
          <Box sx={{ marginLeft: '2%' }}>
              <Doodle seed={simulator?._id['$oid']}/>
          </Box>
          <Stack sx={{ marginLeft: '2%', width:'50%' }}>
              <Box>
                <Stack direction="row" sx={{ height: '100%', alignItems: 'center'}}>
                  <Typography variant='h4' sx={{ fontFamily: 'Playfair Display', marginLeft: '5%', color: 'black'}}>
                      ENARM
                  </Typography>
                  <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', marginLeft: '5%', color: 'black'}}>
                      Iniciaste el {dayjs(simulator.dateCreated['$date']).format("DD [de] MMMM [de] YYYY [a las] HH:mm")}
                  </Typography>
                </Stack>
              </Box>
              {simulator.active ?
                <ExamProgressBar value={simulator.progress? simulator.progress * 100 : 0} />
                :
                null
              } 
          </Stack>
          <Button variant='contained' 
                  onClick={() => props.individualItemAction.action(simulator._id['$oid'])}
                  sx={{ marginLeft: '5%', marginTop: '5%', marginBottom: '5%', backgroundColor: '#F5F5F5', color: 'black', fontFamily: 'Playfair Display', borderColor: 'black'}}>
              {props.individualItemAction.actionMessage}
          </Button>
          {simulator.active ?
            <TimeRemaining initialSeconds={simulator?.timeIntendedMilliseconds / 1000} 
                          elapsedSeconds={simulator?.timeElapsedMilliseconds / 1000} 
                          backgroundColor={'#F1F1F1'} />
            :
            <SimulatorScore score={simulator?.grades.score} maxScore={simulator?.grades.max_score} />
          }
        </Stack>
    </Paper>
  );
}

export default SimulatorListItem;