import * as React from 'react';

import ReactGA from 'react-ga4';

import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import useAxios from '../utils/useAxios';

import PrimaryAppBar from '../components/PrimaryAppBar';
import LandingVideo from '../components/LandingVideo';
import WhyUs from '../components/WhyUs';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';

import { products } from '../utils/constants'

const Landing = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { axios } = useAxios();
    
    const { user } = React.useContext(AuthContext);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, [location]);

    const handleProductSelection = async (lookup_key: string) => {
        if (user) {
          await axios.post('/create-checkout-session/', {
            lookup_key: lookup_key
          })
            .then((response: any) => {
              window.location.href = response.data.url;
            }
          );
        } else {
          navigate('/register?lookup_key=' +  lookup_key);
        }
    }

    function actionWithUser() {
        navigate("/home")
    }

    function actionWithoutUser() {
        navigate("/register")
    }

    return (
        <div>
            <PrimaryAppBar />
            <LandingVideo
                user={user}
                actionWithUser={actionWithUser}
                actionWithoutUser={actionWithoutUser}
            />
            <WhyUs />
            <Pricing
                products={products}
                handleProductSelection={handleProductSelection}
            />
            <Footer />
        </div>
    )
}

export default Landing;