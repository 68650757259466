import * as React from 'react';

import ReactGA from 'react-ga4';

const PrivacyPolicy = () => {

  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
  }, []);

  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to ENARM+, operated by TRUE Data Science & Engineering. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our services.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect the following types of information when you use our website:
      </p>
      <ul>
        <li><strong>Personal Information:</strong> This may include your name, email address, and other identifying information you provide when signing up for an account or using our services.</li>
        <li><strong>Usage Data:</strong> We collect information about how you interact with our website, such as your browsing history, the pages you visit, and your IP address.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>
        We use the information we collect for the following purposes:
      </p>
      <ul>
        <li><strong>Providing Services:</strong> We use your personal information to create and manage your account, provide access to our e-learning content, and communicate with you regarding your account and our services.</li>
        <li><strong>Improving our Services:</strong> We analyze usage data to enhance our website, content, and user experience.</li>
      </ul>

      <h2>3. Sharing Your Data</h2>
      <p>
        We do not sell or share your personal information with third parties, except in the following circumstances:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We may share information with trusted third-party service providers who assist us in delivering our services, but only to the extent necessary. The main one is Google through the Login with Google OAuth service and in an anonymized way using Google Analytics.</li>
        <li><strong>Legal Requirements:</strong> We may disclose information in response to legal requests or to comply with applicable laws and regulations.</li>
      </ul>

      <h2>4. Security</h2>
      <p>
        We take security seriously and employ measures to protect your data from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet is completely secure, and we cannot guarantee the absolute security of your information.
      </p>

      <h2>5. Contact Us</h2>
      <p>
        If you have any questions or concerns about our Privacy Policy or how we handle your data, please contact us at [Your Contact Email Address].
      </p>
    </div>
  );
};

export default PrivacyPolicy;
