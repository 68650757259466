import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import SwipableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import AutoComplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StyleIcon from '@mui/icons-material/Style';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';

import { difficulties, categories, specialties } from '../utils/constants';

import useAxios from '../utils/useAxios';

import { TagType, AnswerType, QuestionType, CaseType } from '../types';

const CreateCase = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();

    const [myCase, setMyCase] = React.useState<CaseType>({
        id: '',
        prompt:'',
        explanation:'',
        difficulty:'',
        category:'',
        specialty:'',
        english:false,
        tags: [],
        questions: []
    });

    const [tags, setTags] = React.useState<TagType[]>([]);
    const [tagRerender, setTagRerender] = React.useState<boolean>(false);

    const [image, setImage] = React.useState<File | null>(null);

    const [questionToDelete, setQuestionToDelete] = React.useState<number>(-1);

    const [openDeleteQuestionConfirm, setOpenDeleteQuestionConfirm] = React.useState<boolean>(false);

    const [emptyFields, setEmptyFields] = React.useState<boolean>(false);
    const [emptyFieldsAlert, setEmptyFieldsAlert] = React.useState<string>('');

    const [publishSuccess, setPublishSuccess] = React.useState<boolean>(false);
    const [publishError, setPublishError] = React.useState<boolean>(false);

    const [closeCase, setCloseCase] = React.useState<boolean>(false);
    
    React.useEffect(() => {
        const getTags = async () => {
            const { data } = await axios.get('/tags');
            setTags(data);
        };
        if (initialized) getTags();
    }, [initialized]);

    const handleAddQuestion = () => {
        setMyCase({...myCase, questions: [...myCase.questions, { id:'', prompt: '', order: 0, flashcard: false, answers: [{id:'', prompt:'', correct:false}], collapsed:true}]})
    }

    const handleDeleteQuestion = (qIndex:number) => {
        const questions = myCase.questions.filter((question, i) =>  i !== qIndex )
        setMyCase({...myCase, questions: questions})
    }   

    const handleQuestionFlashcard = (qIndex:number) => {
        const questions = myCase.questions.map((question, i) => {
            if (i === qIndex) {
                return {...question, flashcard: !question.flashcard}
            } else {
                return question
            }
        })
        setMyCase({...myCase, questions: questions})
    }
    
    const handleQuestionChange = (qIndex:number, value:string) => {
        const questions = myCase.questions.map((question, i) => {
            if (i === qIndex) {
                return {...question, prompt:value}
            } else {
                return question
            }
        })
        setMyCase({...myCase, questions: questions})
    }

    const handleAddAnswer = (qIndex:number) => {
        const questions = myCase.questions.map((question, i) => {
            if (i === qIndex) {
                return {...question, answers:[...question.answers, {id:'', prompt:'', correct:false}]}
            } else {
                return question
            }
        })
        setMyCase({...myCase, questions: questions})
    }

    const handleDeleteAnswer = (qIndex:number, aIndex:number) => {
        const questions = myCase.questions.map((question, i) => {
            if (i === qIndex) {
                const newAnswers = question.answers.filter((answer, j) => j !== aIndex)
                return {...question, answers:newAnswers}
            } else {
                return question
            }
        })
        setMyCase({...myCase, questions: questions})
    }

    const handleAnswerChange = (qIndex:number, aIndex:number, value:string) => {
        const questions = myCase.questions.map((question, i) => {
            if (i === qIndex) {
                const newAnswers = question.answers.map((answer, j) => {
                    if (j === aIndex) {
                        return {...answer, prompt:value}
                    }
                    else {
                        return answer
                    }
                })
                return {...question, answers:newAnswers}
            } else {
                return question
            }
        })
        setMyCase({...myCase, questions: questions})
    }

    const handleSelectCorrect = (qIndex:number, aIndex:number) => {
        const questions = myCase.questions.map((question, i) => {
            if (i === qIndex) {
                const newAnswers = question.answers.map((answer, j) => { 
                    if (j === aIndex) {
                        return {...answer, correct: !answer.correct}
                    }
                    else {
                        return {...answer, correct: false}
                    }
                })
                return {...question, answers:newAnswers}
            } else {
                return question
            }
        })
        setMyCase({...myCase, questions: questions})
    }

    const handleAddTags = (tags:Array<string>) => {
        const newTags = tags.map((tag) => {
            return {id:'', name:tag}
        })
        setMyCase({...myCase, tags: newTags})
    }

    const handleImageChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setImage(e.target.files[0])
        }
    }
  
    const handlePublish = async () => {

        const caseToPublish = {
            ...myCase,
            questions: myCase.questions.map((question, i) => {
                const newAnswers = question.answers.map((answer) => {
                    return {...answer, id:undefined}
                })
                return {...question, order:i, collapsed:undefined, id:undefined, answers:newAnswers}
            }),
            id: undefined,
            image: image
        }

        let emptyFields = '';

        if (caseToPublish.prompt === '') {
            emptyFields = emptyFields + 'Descripción del caso. '
        }
        if (caseToPublish.explanation === '') {
            emptyFields = emptyFields + 'Explicación del caso. '
        }
        if (caseToPublish.difficulty === '') {
            emptyFields = emptyFields + 'Dificultad del caso. '
        }
        if (caseToPublish.category === '') {
            emptyFields = emptyFields + 'Categoría del caso. '
        }
        if (caseToPublish.specialty === '') {
            emptyFields = emptyFields + 'Especialidad del caso. '
        }
        if (caseToPublish.questions.length === 0) {
            emptyFields = emptyFields + 'Preguntas del caso. '
        }
        caseToPublish.questions.forEach((question, i) => {
            if (question.prompt === '') {
                emptyFields = emptyFields + `Pregunta ${i+1}. `
            }
            if (question.answers.length === 0) {
                emptyFields = emptyFields + `Respuestas de la pregunta ${i+1}. `
            }
            let hasCorrectAnswer = false
            question.answers.forEach((answer, j) => {
                if (answer.prompt === '') {
                    emptyFields = emptyFields + `Respuesta ${j+1} de la pregunta ${i+1}. `
                }
                if (answer.correct) {
                    hasCorrectAnswer = true
                }
            })
            if (!hasCorrectAnswer) {
                emptyFields = emptyFields + `Alguna respuesta correcta para la pregunta ${i+1}. `
            }
        })
        if (emptyFields !== '') {
            setEmptyFields(true)
            setEmptyFieldsAlert('Los siguientes campos están vacíos: ' + emptyFields)
        } else {
            setEmptyFields(false)
            setEmptyFieldsAlert('')
            try {
                const recentlyCreatedCase = await axios.post('/cases/', caseToPublish)
                if (image) {
                    const formData = new FormData()
                    formData.append('image', image)
                    await axios.post(`/cases/${recentlyCreatedCase.data.id}/image/`, formData)
                }
                setPublishSuccess(true)
                setTagRerender(!tagRerender)
                setMyCase({
                    id: '',
                    prompt:'',
                    explanation:'',
                    difficulty:'',
                    category:'',
                    specialty:'',
                    english:false,
                    tags: [],
                    questions: []
                })
                setImage(null)
            } catch (error) {
                setPublishError(true)
                console.log(error)
            }
        }
    }

    const handleCollapse = (qIndex:number) => {
        const questions = myCase.questions.map((question, i) => {
            if (i === qIndex) {
                return {...question, collapsed:!question.collapsed}
            } else {
                return question
            }
        })
        setMyCase({...myCase, questions: questions})
    }

    const handleRaise = (qIndex:number) => {
        if (qIndex === 0) {
            return
        }
        const questions = myCase.questions
        const temp = questions[qIndex]
        questions[qIndex] = questions[qIndex-1]
        questions[qIndex-1] = temp

        setMyCase({...myCase, questions: questions})
    }

    const handleLower = (qIndex:number) => {
        if (qIndex === myCase.questions.length-1) {
            return
        }
        const questions = myCase.questions
        const temp = questions[qIndex]
        questions[qIndex] = questions[qIndex+1]
        questions[qIndex+1] = temp

        setMyCase({...myCase, questions: questions})
    }

    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', m:'5%'}}>
            <Box sx={{ display: 'flex'}}>
                <SwipableDrawer
                    sx={{
                    width: '20%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width: '20%',
                        boxSizing: 'border-box',
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                    },
                    }}
                    variant="permanent"
                    anchor="left"
                    color='primary'
                    open={true}
                    onOpen={() => {}}
                    onClose={() => {}}
                    style={{zIndex:1099}}
                    >
                    <ClearIcon onClick={() => {
                            setCloseCase(true);
                        }} sx={{ cursor: 'pointer', marginTop: '5%'}}/>
                    <Stack sx={{width:'90%', display:'flex', p:'5%'}}>
                        <Stack sx={{display:'flex', pt:'5%', pr:'5%', pl:'5%'}}>
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                Categoría
                            </Typography>
                            <Divider sx={{width:'90%'}}/>
                        </Stack>
                        <Stack spacing='2%' sx={{display:'flex', justifyContent:'center', pt:'5%', pr:'10%', pl:'10%'}}>
                            <Select
                                value={myCase.category}
                                onChange={(e) => setMyCase({...myCase, category:e.target.value as string})}>
                                    {Array.from(categories.map.entries()).map((pair:any) =>
                                        <MenuItem key={pair[0]} value={pair[0]}>{pair[1]}</MenuItem>
                                    )}
                            </Select>
                        </Stack>
                    </Stack>

                    <Stack sx={{width:'90%', display:'flex', p:'5%'}}>
                        <Stack sx={{display:'flex', pt:'5%', pr:'5%', pl:'5%'}}>
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                Especialidad
                            </Typography>
                            <Divider sx={{width:'90%'}}/>
                        </Stack>
                        <Stack spacing='2%' sx={{display:'flex', justifyContent:'center', pt:'5%', pr:'10%', pl:'10%'}}>
                            <Select
                                value={myCase.specialty}
                                onChange={(e) => setMyCase({...myCase, specialty:e.target.value as string})}>
                                    {Array.from(specialties.map.entries()).map((pair:any) =>
                                        <MenuItem key={pair[0]} value={pair[0]}>{pair[1]}</MenuItem>
                                    )}
                            </Select>
                        </Stack>
                    </Stack>

                    <Stack sx={{width:'90%', display:'flex', p:'5%'}}>
                        <Stack sx={{display:'flex', pt:'5%', pr:'5%', pl:'5%'}}>
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                Tags
                            </Typography>
                            <Divider sx={{width:'90%'}}/>
                        </Stack>
                        <Stack spacing='2%' sx={{display:'flex', justifyContent:'center', pt:'5%', pr:'10%', pl:'10%'}}>
                            <AutoComplete
                                key={tagRerender.toString()}
                                multiple
                                value={myCase.tags.map((tag) => tag.name)}
                                options={tags.map((tag) => tag.name)}
                                defaultValue={[]}
                                onChange={(e, value) => handleAddTags(value)}
                                freeSolo
                                renderTags={(value: readonly string[]) =>
                                    value.map((option: string, index: number) => (
                                        <Chip key={index} variant="outlined" label={option} />
                                    ))
                                }
                                renderInput={(params:any) => (
                                <TextField
                                    {...params}
                                />
                            )}/>
                        </Stack>
                    </Stack>

                    <Stack sx={{width:'90%', display:'flex', p:'5%'}}>
                        <Stack sx={{display:'flex', pt:'5%', pr:'5%', pl:'5%'}}>
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                Dificultad
                            </Typography>
                            <Divider sx={{width:'90%'}}/>
                        </Stack>
                        <Stack spacing='2%' sx={{width:'40%', display:'flex', justifyContent:'center', pt:'5%', pr:'10%', pl:'10%'}}>
                            {Array.from(difficulties.map.entries()).map((pair:any) =>
                                <Button key={pair[0]} variant={myCase.difficulty===pair[0] ? 'contained' : 'outlined'} onClick={() => setMyCase({...myCase, difficulty:pair[0]})}>{pair[1]}</Button>
                            )}
                        </Stack>
                    </Stack>

                    <Stack sx={{width:'90%', display:'flex', p:'5%'}} spacing={2}>
                        <Stack sx={{display:'flex', pt:'5%', pr:'5%', pl:'5%'}}>
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                Imagen
                            </Typography>
                            <Divider sx={{width:'90%'}}/>
                        </Stack>
                        <input
                            name='image'
                            type='file'
                            onChange={(e) => handleImageChange(e)}
                        />
                    </Stack>

                    <Stack sx={{width:'90%', display:'flex', p:'5%'}}>
                        <Stack sx={{display:'flex', pt:'5%', pr:'5%', pl:'5%'}}>
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                Inglés
                            </Typography>
                            <Divider sx={{width:'90%'}}/>
                        </Stack>
                        <Stack direction='row' spacing='2%' sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:'5%', pr:'10%', pl:'10%'}}>
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                No
                            </Typography>
                            <Switch
                                checked={myCase.english}
                                onChange={(e) => setMyCase({...myCase, english:e.target.checked})}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography variant="subtitle1" sx={{textAlign:'left'}}>
                                Sí
                            </Typography>
                        </Stack>
                    </Stack>

                    {emptyFields ? 
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                            <Alert severity='warning' action={<IconButton onClick={() => setEmptyFields(false)}><CloseIcon/></IconButton>}>{emptyFieldsAlert}</Alert>
                        </Box>
                    : null}

                    {publishSuccess ? 
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                            <Alert severity='success' action={<IconButton onClick={() => setPublishSuccess(false)}><CloseIcon/></IconButton>}>Caso publicado con éxito</Alert>
                        </Box>
                    : null}

                    {publishError ? 
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                            <Alert severity='error' action={<IconButton onClick={() => setPublishError(false)}><CloseIcon/></IconButton>}> No se pudo publicar el caso</Alert>
                        </Box>
                    : null}

                    <Button variant='contained' onClick={() => handlePublish() } sx={{m:'10%', backgroundColor:'#03C03C', '&:hover': {backgroundColor:'#048a2c'} }}>
                        Publicar
                    </Button>

                </SwipableDrawer>
            </Box>

            <Box>
                <Dialog open={openDeleteQuestionConfirm} onClose={() => setOpenDeleteQuestionConfirm(false)}>
                    <DialogTitle>¿Seguro que quieres eliminar la Pregunta {questionToDelete+1}?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {setOpenDeleteQuestionConfirm(false)}}>Cancelar</Button>
                        <Button onClick={() => {handleDeleteQuestion(questionToDelete); setOpenDeleteQuestionConfirm(false)}}>Eliminar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={closeCase} onClose={() => setCloseCase(false)}>
                    <DialogTitle>¿Seguro que quieres cerrar el caso?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {setCloseCase(false)}}>Cancelar</Button>
                        <Button onClick={() => {navigate('/cases'); setCloseCase(false)}}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            

            <Box sx={{width:'70%', ml:'20%'}}>
                <TextField
                value={myCase.prompt}  
                onChange={(e) => setMyCase({...myCase, prompt: e.target.value})}
                multiline label='Caso' rows={7} sx={{display:'flex', width:'100%', mb:'5%'}}/>
                <TextField 
                value={myCase.explanation}
                onChange={(e) => setMyCase({...myCase, explanation: e.target.value})}
                multiline label='Explicación' rows={7} sx={{display:'flex', width:'100%', mb:'5%'}}/>
                {myCase.questions.map((question:QuestionType, qIndex:number) => {
                    return (
                        <Stack direction='row' key={qIndex} sx={{display:'flex', mt:'3%', mb:'3%'}}>
                            <Stack sx={{mr:'1%'}}>
                                <IconButton color='primary' onClick={() => handleRaise(qIndex)} sx={{borderRadius:0, border:'1px solid rgba(201, 201, 201, 0.47)'}}><ArrowUpwardIcon/></IconButton>
                                <IconButton color='primary' onClick={() => handleLower(qIndex)} sx={{borderRadius:0, border:'1px solid rgba(201, 201, 201, 0.47)'}}><ArrowDownwardIcon/></IconButton>
                            </Stack>
                            <Box sx={{ width:'95%', boxShadow: '0px 4px 10px 0px rgba(201, 201, 201, 0.47)' }}>
                                <Stack spacing='2%' sx={{ p:'2%' }}>
                                    <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <TextField multiline label={'Pregunta '+(qIndex+1).toString()} sx={{width:'90%', display:'flex'}} value={question.prompt} onChange={(e) => handleQuestionChange(qIndex, e.target.value)}>
                                            {question.prompt}
                                        </TextField>
                                        {myCase.questions[qIndex].flashcard ? 
                                            <Tooltip title='Remover flashcard' placement='top'>
                                                <Button variant='contained' onClick={() => handleQuestionFlashcard(qIndex)} color='secondary'><StyleIcon/></Button>
                                            </Tooltip>
                                            : 
                                            <Tooltip title='Agregar flashcard' placement='top'>
                                                <Button onClick={() => handleQuestionFlashcard(qIndex)} sx={{color:'gray'}}><StyleIcon/></Button>
                                            </Tooltip>
                                        }
                                    </Box>
                                    <Box>
                                        {myCase.questions[qIndex].collapsed ? <Button onClick={() => handleCollapse(qIndex)}> <ExpandLess /> </Button> : <Button onClick={() => handleCollapse(qIndex)} sx={{ml:'1%'}}> <ExpandMore /> </Button>}
                                    </Box>
                                    <Collapse in={myCase.questions[qIndex].collapsed} timeout="auto" unmountOnExit>
                                        <Stack spacing='1%' sx={{display:'flex', justifyContent:'right', alignItems:'left', p:'3%', boxShadow: '2px 4px 10px 1px rgba(201, 201, 201, 0.47)', borderRadius:'0.5rem', mb:'3%'}}>
                                            <Box sx={{display:'flex', justifyContent:'left', mb:'3%'}}>
                                                <Typography variant='body2'>
                                                    Respuestas
                                                </Typography>
                                            </Box>
                                            {question.answers.map((answer:AnswerType, aIndex:number) => {
                                                return (
                                                    <Stack direction='row' key={aIndex} spacing='1%'>
                                                        <Checkbox
                                                        checked={myCase.questions[qIndex].answers[aIndex].correct}
                                                        onChange={() => handleSelectCorrect(qIndex, aIndex)}
                                                        icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                                                        checkedIcon={<CheckCircleIcon fontSize="medium" />}
                                                        disableRipple={true}
                                                        />
                                                        <TextField multiline sx={{backgroundColor:'white', borderRadius:'2rem', width:'100%'}} value={answer.prompt} onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)}>
                                                            {answer.prompt}
                                                        </TextField>
                                                        <Button onClick={() => handleDeleteAnswer(qIndex, aIndex)}> <CloseIcon/> </Button>
                                                    </Stack>
                                                )
                                            })}
                                        </Stack>
                                        <Button variant='outlined' onClick={() => handleAddAnswer(qIndex)}>
                                            Respuesta +
                                        </Button>
                                    </Collapse>
                                </Stack>
                            </Box>
                            <Stack sx={{ml:'1%'}}>
                                <IconButton onClick={() => { setOpenDeleteQuestionConfirm(true); setQuestionToDelete(qIndex)}  } color='error' sx={{borderRadius:0, border:'1px solid rgba(201, 201, 201, 0.47)'}}><DeleteForeverIcon/></IconButton>
                            </Stack>
                        </Stack>
                    )
                })}
                <Stack spacing='4%' sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                    <Button variant='outlined' onClick={() => handleAddQuestion() }>
                        Pregunta +
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}

export default CreateCase