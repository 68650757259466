import * as React from "react";

import ReactGA from "react-ga4";

import useAxios from "../utils/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

const Verify = () => { 
    const { axios, initialized } = useAxios();

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const resendActivationEmail = async () => {
        try {
            await axios.post(`${baseURL}users/resend-activation-email/`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <h1>Verifica tu cuenta.</h1>
            <p>Revisa tu correo y da click en la liga de verificación.</p>
            <button onClick={resendActivationEmail}>REENVIAR CORREO</button>
        </div>
    );
};

export default Verify;