import { useContext, useState, useEffect } from 'react';

import ReactGA from 'react-ga4';

import PrimaryAppBar from '../components/PrimaryAppBar';
import GoogleSocialAuth from '../components/auth/GoogleSocialAuth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import AuthContext from "../context/AuthContext";
import { Link, useSearchParams } from 'react-router-dom'

//material-ui colors
import { yellow } from '@mui/material/colors';

const theme = createTheme();

const Register = () => {
    const { registerUser } = useContext(AuthContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = String(data.get('email'))
        const firstName = String(data.get('firstName'))
        const lastName = String(data.get('lastName'))
        const password = data.get('password')
        const password2 = data.get('password2')
        username.length > 0 && registerUser(username, firstName, lastName, password, password2, searchParams.get('lookup_key'), loading, setLoading);
    }

    return (
        <div>
            <PrimaryAppBar />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                    <Box
                        sx={{
                        my: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginLeft: '25%',
                        marginRight: '25%',
                        }}
                    >
                        <Avatar sx={{ bgcolor: yellow[600] }}>
                            <LockOutlinedIcon  />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                        Registro
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Correo electrónico"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Box sx={{ display: 'space-between', justifyContent: 'center', alignItems: 'center' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    name="firstName"
                                    label="Nombre"
                                    type="text"
                                    id="firstName"
                                    sx={{ marginRight: 1}}
                                />
                                <TextField
                                    margin='normal'
                                    required
                                    name="lastName"
                                    label="Apellido"
                                    type="text"
                                    id="lastName"
                                    sx={{ marginLeft: 1}}
                                />
                            </Box>
                            <Stack>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password2"
                                label="Confirmar contraseña"
                                type="password"
                                id="password2"
                                autoComplete="current-password"
                            />
                            </Stack>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, backgroundColor: '#3f51b5', borderRadius: 0 }}
                                loading={loading}
                            >
                                Registrarse
                            </LoadingButton>
                            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                                <Grid container>
                                    <Grid item xs>
                                        <Link to="/">
                                            Regresar
                                        </Link>
                                    </Grid>
                                    <Grid item xs>
                                        <Link to="/login">
                                            ¿Ya tienes cuenta?
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                            <br />
                            <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'center'}}>
                                <GoogleSocialAuth />
                            </Stack>
                        </Box>
                    </Box>
        </ThemeProvider>
        </div>
    );
}

export default Register;