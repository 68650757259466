import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type MainCardTableProps = {
    data:any,
    headerColor?: string,
    headerTextColor?: string,
}

const MainCardTable = (props: MainCardTableProps) => {
    const columns = Object.keys(props.data[0]);
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead sx={{backgroundColor: props.headerColor}}>
                    <TableRow>
                        {columns.map((column: any, index:any) => (
                            <TableCell key={index} sx={{color: props.headerTextColor}}>
                                <Typography variant="subtitle1">{column}</Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row:any, index:any) => (
                        <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns.map((column: any, index:any) => (
                                <TableCell key={index}>
                                    <Box sx={{display:'flex', justifyContent:'center', pt:'15%', pb:'15%', border:'1px solid', borderColor:'grey.300', borderRadius:'5%'}}>
                                        <Typography variant="h5">{row[column]}</Typography>
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MainCardTable;