import useAxios from "../utils/useAxios";
import { createContext, useState, useEffect, useContext } from "react";
import AuthContext from "./AuthContext";

type UserContextData = {
    userinfo: any;
    setUserinfo: any;
    profile: any;
    setProfile: any;
}


const UserContext = createContext({} as UserContextData);

export default UserContext;

export const UserProvider = ({ children }: {children: any}) => {
    const { user } = useContext(AuthContext);
    const [userinfo, setUserinfo] = useState(() =>
      localStorage.getItem("userinfo")
        ? JSON.parse(localStorage.getItem("userinfo") || '{}')
        : null
    );
    const [profile, setProfile] = useState(() =>
      localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile") || '{}')
        : null
    );
    const { axios, initialized } = useAxios();
    useEffect(() => {
        const getUserinfo = async () => {
            try {
                const response = await axios.get("/user");
                setUserinfo(response.data);
                localStorage.setItem("userinfo", JSON.stringify(response.data));
            } catch (e: any) {
                if (e.response?.status === 401) {
                    localStorage.removeItem("userinfo");
                    localStorage.removeItem("profile");
                }
            }
        }
        const getProfile = async () => {
            try {
                const response = await axios.get("/profile");
                setProfile(response.data);
                localStorage.setItem("profile", JSON.stringify(response.data));
            } catch (e: any) {
                if (e.response?.status === 401) {
                    console.log(e)
                    localStorage.removeItem("userinfo");
                    localStorage.removeItem("profile");
                }
            }   
        }
        if (initialized && user) {
            getUserinfo();
            getProfile();
        }
    }, [user, initialized]);

    return (
        <UserContext.Provider value={{userinfo, setUserinfo, profile, setProfile}}>
            {children}
        </UserContext.Provider>
    );
}