import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import { deepPurple } from '@mui/material/colors';

import { CaseType, QuestionType } from '../..//types';

type NavigatorProps = {
    examData: any,
    completedCases: Set<string>,
    completedQuestions: Set<string>,
    handleChangeCase: (index: number) => void,
    currentCase: number,
}

const Navigator = (props: NavigatorProps) => {
    const examData = props.examData;
    const completedCases = props.completedCases;
    const completedQuestions = props.completedQuestions;
    const handleChangeCase = props.handleChangeCase;
    const currentCase = props.currentCase;

    const [byCase, setByCase] = React.useState(true);

    const allQuestionsWithCase = examData.cases.map((caseItem:CaseType, caseIndex:number) => {
        return caseItem.questions.map((questionItem:QuestionType, qIndex:number) => {
            return {
                caseId: caseIndex,
                question: questionItem
            }
        })
    }).flat()
        
    return (
        <Box>
            <Paper sx={{ height:'5vh', display:'flex', alignItems:'center', justifyContent:'center', bgcolor: '#002244'}}>
                <Typography variant='subtitle1' sx={{ color: 'white', textAlign:'center'}}>
                    {byCase ? 
                        `Progreso ${completedCases.size}/${examData.cases.length}` 
                        : 
                        `Progreso ${completedQuestions.size}/${allQuestionsWithCase.length}`
                    }
                </Typography>
            </Paper>

            <Stack direction='row' spacing='1%' sx={{display:'flex', justifyContent:'center', alignItems:'center', p:'2%'}}>
                <Typography variant="body1" sx={{textAlign:'left'}}>
                    Casos
                </Typography>
                <Switch
                    checked={!byCase}
                    onChange={(e) => setByCase(!e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="body1" sx={{textAlign:'right'}}>
                    Preguntas
                </Typography>
            </Stack>

            <Paper sx={{ maxHeight:'35vh', overflow:'scroll', overflowX:'hidden' }}>
                {byCase ?
                    <Grid container sx={{width:'100%', display:'flex', height:'95%', alignItems:'center', justifyContent:'center', pb:'2%', pt:'2%', pr:'6%', pl:'6%' }}>
                        {examData.cases.map((caseItem:CaseType, index:number) => (
                            <Grid key={index} item xs={12/5} sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', pb:'2%'}}>
                                <Box sx={{width:'70%'}}>
                                    <Tooltip title={completedCases.has(caseItem.id) ? 'Caso guardado' : 'Caso sin responder'} placement="top" arrow enterDelay={2000}>
                                        <IconButton onClick={() => handleChangeCase(index) }
                                                sx={{width:'100%', borderRadius:'0%', p:'0%', m:'0%'}}>
                                            <Grid container sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', 
                                                                borderRadius:'10%',
                                                                border: currentCase === index ? '3px solid' : '1px solid',
                                                                backgroundColor: completedCases.has(caseItem.id) ? deepPurple[500]: 'gray'}}>
                                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'10%',
                                                                        backgroundColor: 'white'}}>
                                                    <p style={{fontSize:'0.8rem', color: 'black', fontFamily:'Arial'}}>
                                                        {index+1}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={12} sx={{height:'0.4rem'}} />
                                            </Grid>
                                        </IconButton>  
                                    </Tooltip>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Grid container sx={{width:'100%', display:'flex', height:'95%', alignItems:'center', justifyContent:'center', pb:'2%', pt:'2%', pr:'6%', pl:'6%' }}>
                        {allQuestionsWithCase.map((questionItem:any, qIndex:number) => (
                            <Grid key={qIndex} item xs={12/5} sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', pb:'2%'}}>
                                <Box sx={{width:'70%'}}>
                                    <Tooltip title={completedQuestions.has(questionItem.question.id) ? 'Pregunta guardada' : 'Pregunta sin responder'} placement="top" arrow enterDelay={2000}>
                                        <IconButton onClick={() => handleChangeCase(questionItem.caseId) }
                                                sx={{width:'100%', borderRadius:'0%', p:'0%', m:'0%'}}>
                                            <Grid container sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', 
                                                                borderRadius:'10%',
                                                                border: currentCase === questionItem.caseId ? '3px solid' : '1px solid',
                                                                backgroundColor: completedQuestions.has(questionItem.question.id) ? deepPurple[500]: 'gray'}}>
                                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'10%',
                                                                        backgroundColor: 'white'}}>
                                                    <p style={{fontSize:'0.8rem', color: 'black', fontFamily:'Arial'}}>
                                                        {qIndex+1}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={12} sx={{height:'0.4rem'}} />
                                            </Grid>
                                        </IconButton>  
                                    </Tooltip>
                                </Box>
                            </Grid>
                        ))}    
                    </Grid>
                } 
            </Paper>
        </Box>
    )
}

export default Navigator;