import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Badge from '@mui/material/Badge'

import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

interface ProfileTabsProps {
    currentTab: number;
    handleTabChange: (newValue: number) => void;
}

const tabs = [
    {
      label: 'Actividad',
      icon: FolderCopyOutlinedIcon
    },
    {
      label: 'Estadísticas',
      icon: InsertChartOutlinedIcon
    },
    {
      label: 'Ajustes',

      icon: SettingsOutlinedIcon
    }
]

const activeTabStyle = {
  borderBottom: '3px solid #3f51b5',
}

const inactiveTabStyle = {
  borderBottom: '3px solid transparent',
}

const ProfileTabs = (props: ProfileTabsProps) => {
  return (
    <Box>
      <Stack direction="row" sx={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
          {tabs.map((tab, index) => (
              <Stack key={index} spacing='1rem' sx={{ display:'flex', alignItems:'left', justifyContent:'center', mr:'5rem'}}>
                <Badge color="primary" badgeContent={1} sx={{ width: '2rem', height: '2rem', '& .MuiBadge-badge': { top: '1.5rem', border: '3px solid #fff', padding: '0 4px' } }}>
                  <tab.icon sx={{ width:'2rem', height:'2rem', cursor: 'pointer'}} onClick={() => props.handleTabChange(index)}/>
                </Badge>
                <Typography variant="body1" textAlign='left' onClick={() => props.handleTabChange(index)}
                            sx={props.currentTab === index ? {...activeTabStyle, cursor: 'pointer', paddingBottom: '0.2rem'} 
                                                           : {...inactiveTabStyle, cursor: 'pointer', paddingBottom: '0.2rem'}}>
                    {tab.label}
                </Typography>
            </Stack>
          ))}
      </Stack>
    </Box>
  )
}

export default ProfileTabs