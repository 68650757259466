import * as React from 'react';
import CircularProgressWithLabel from './CircularProgressWithLabel';

type TimeRemainingProps = {
    initialSeconds: number,
    elapsedSeconds: number,
    backgroundColor?: string,
    size?: number,
};

const TimeRemaining= (props: TimeRemainingProps) => {
    const remainingSeconds = Math.max(props.initialSeconds - props.elapsedSeconds, 0);
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    const progress = props.elapsedSeconds / props.initialSeconds
    const label = minutes.toString().padStart(2, '0') + ':' + seconds.toFixed(0).toString().padStart(2, '0');
    return (
        <CircularProgressWithLabel progress={progress} label={label} backgroundColor={props.backgroundColor} size={props.size}/>
    );
}

export default TimeRemaining;