import React from 'react';

import ProfileData from './ProfileData';
import ProfileTabs from './ProfileTabs';

import ActivityHistory from './ActivityHistory';
import Statistics from './Statistics';
import Settings from './Settings';


import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

interface ProfilePageProps {
    firstName?: string;
    lastName?: string;
    email?: string;
    verified: boolean;
    stripe_subscription_status: string;
    stripe_subscription_current_period_end: string;
    showSubscriptionDetail: () => void;
    simulators: any;
}


const ProfilePage = (props: ProfilePageProps) => {

  const [tab, setTab] = React.useState(0);

  const tabContent = [
    <ActivityHistory simulators={props.simulators}/>,
    <Statistics simulators={props.simulators}/>,
    <Settings 
      firstName={props?.firstName} 
      lastName={props?.lastName} 
      verified={props.verified} 
      stripe_subscription_status={props.stripe_subscription_status}
      stripe_subscription_current_period_end={props.stripe_subscription_current_period_end}
      showSubscriptionDetail={props.showSubscriptionDetail}
    />

  ]

  const handleTabChange = (newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ marginTop: '0%'}}>
      <Paper elevation={3} sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', p: '2%' }}>
        <ProfileData firstName={props?.firstName} lastName={props?.lastName} email={props?.email}/>
        <ProfileTabs currentTab={tab} handleTabChange={handleTabChange}/>
      </Paper>
      <Box sx={{pt:'2%', pl:'2%', pr:'1%'}}>
        <Box sx={{  minHeight:'60vh', maxHeight:'60vh', overflow:'scroll', overflowX:'hidden'}}>
          {tabContent[tab]}
        </Box>
      </Box>
      
    </Box>
  );
}

export default ProfilePage;