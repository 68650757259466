import * as React from 'react';

import Chart from 'react-apexcharts';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { summarizeDifficulties, summarizeCategories, summarizeSpecialties } from '../utils/reviewSummary';


const ReviewStats = (props) => {
    
    const difficultiesOptions = {
        chart: {
            width: 300,
            type: "donut",
        },
        labels: summarizeDifficulties(props.questions).labels,
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                    }
                }
            },
        },
        legend: {
            show: false,
        },
    }

    const categoriesOptions = {
        chart: {
            width: 300,
            type: "donut",
        },
        labels: summarizeCategories(props.questions).labels,
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                    }
                }
            },
        },
        legend: {
            show: false,
        },
    }

    const specialtiesOptions = {
        chart: {
            width: 300,
            type: "donut",
        },
        labels: summarizeSpecialties(props.questions).labels,
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                    }
                }
            },
        },
        legend: {
            show: false,
        },
    }

    return (
        <div>
            <Stack direction="row" spacing={4}>
                <div>
                    <Paper sx={{ padding: '2%'}} elevation={3}>
                        <Typography variant="h4" sx={{ fontFamily: 'Playfair Display'}}>
                            Dificultades
                        </Typography>
                        <Chart options={difficultiesOptions} series={summarizeDifficulties(props.questions).series} type="donut" width="300" />
                    </Paper>
                </div>
                <div>
                    <Paper sx={{ padding: '2%'}} elevation={3}>
                        <Typography variant="h4" sx={{ fontFamily: 'Playfair Display'}}>
                            Categorías
                        </Typography>
                        <Chart options={categoriesOptions} series={summarizeCategories(props.questions).series} type="donut" width="300" />
                    </Paper>
                </div>
                <div>
                    <Paper sx={{ padding: '2%'}} elevation={3}>
                        <Typography variant="h4" sx={{ fontFamily: 'Playfair Display'}}>
                            Especialidades
                        </Typography>
                        <Chart options={specialtiesOptions} series={summarizeSpecialties(props.questions).series} type="donut" width="300" />
                    </Paper>
                </div>
            </Stack>
        </div>
    )
}

export default ReviewStats;