import * as React from 'react';

import ReactGA from 'react-ga4';

import { useNavigate, useSearchParams } from 'react-router-dom';

import SimulatorAppBar from '../components/SimulatorAppBar';
import FinalizeExamDialog from '../components/FinalizeExamDialog';
import SimulatorSpeedDial from '../components/SimulatorSpeedDial';
import MainSimulator from '../components/MainSimulator';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CheckIcon from '@mui/icons-material/Check';
import GridOnIcon from '@mui/icons-material/GridOn';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { CaseType, QuestionType } from '../types';

import { difficultyScores } from '../utils/constants';

import demo from '../utils/demo';


const SimulatorDemo = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const view = searchParams.get('view');

    const [examData, setExamData] = React.useState<any>(null);
    const [markedCases, setMarkedCases] = React.useState<Array<number>>([]);

    const [completedCases, setCompletedCases] = React.useState<Set<string>>(new Set());
    const [completedQuestions, setCompletedQuestions] = React.useState<Set<string>>(new Set());

    const [currentCase, setCurrentCase] = React.useState<number>(0);

    const [timeElapsed, setTimeElapsed] = React.useState<number>(0);

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(true);
    const [finishDialogOpen, setFinishDialogOpen] = React.useState<boolean>(false);

    const caseRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const markCase = (caseIndex: number) => {
        const newMarkedCases = markedCases;
        newMarkedCases.push(caseIndex);
        setMarkedCases(newMarkedCases);
    }

    const unmarkCase = (caseIndex: number) => {
        const newMarkedCases = markedCases.filter((markedCase) => markedCase !== caseIndex);
        setMarkedCases(newMarkedCases);
    }

    const isQuestionCompleted = (QuestionItem: QuestionType) => {
        const answers = QuestionItem.answers;
        return answers.some((answer) => answer.selected);
    }

    const isCaseCompleted = (CaseItem: CaseType) => {
        const questions = CaseItem.questions;
        return questions.every((question) => isQuestionCompleted(question));
    }

    const checkCompletion = (caseItem: CaseType) => {

        const newCompletedQuestions = completedQuestions;
        for (const question of caseItem.questions) {
            if (isQuestionCompleted(question)) {
                newCompletedQuestions.add(question.id)
                setCompletedQuestions(newCompletedQuestions);
            } else {
                newCompletedQuestions.delete(question.id);
                setCompletedQuestions(newCompletedQuestions);
            }
        }

        const newCompletedCases = completedCases
        if (isCaseCompleted(caseItem)) {
            newCompletedCases.add(caseItem.id);
            setCompletedCases(newCompletedCases);
        } else {
            newCompletedCases.delete(caseItem.id);
            setCompletedCases(newCompletedCases);
        }
    }

    const handlePutElapsedTime = async () => {
        const timeElapsedMilliseconds = timeElapsed * 1000;
        try {
            localStorage.setItem('timeElapsedDemo', timeElapsedMilliseconds.toString());
        } catch (error) {
            console.log(error);
        }
    }

    const handleAnswerSelected = (selectedCase:CaseType, qIndex:number, aIndex:number) => {
        const questions = selectedCase.questions.map((question:any, i:number) => {
            if (i === qIndex) {
                const newAnswers = question.answers.map((answer:any, j:number) => { 
                    if (j === aIndex) {
                        return {...answer, selected: !answer.selected}
                    }
                    else {
                        return {...answer, selected: false}
                    }
                })
                return {...question, answers:newAnswers}
            } else {
                return question
            }
        })
        const newSelectedCase = {...selectedCase, questions: questions}
        const newCases = examData.cases.map((caseItem:CaseType) => {
            if (caseItem.id === newSelectedCase.id) {
                return newSelectedCase
            } else {
                return caseItem
            }
        })
        setExamData({...examData, cases: newCases});
    }

    const handleChangeCase = (newCase: number) => {
        checkCompletion(examData.cases[currentCase])
        setCurrentCase(newCase)
        caseRef.current?.scrollIntoView({behavior: "smooth", block: "start"})
    }


    const handleExitExam = () => {
        navigate('/home');
    }

    function addOneToObjectAttribute(obj: any, attr: string) {
        if (obj) {
            if (obj[attr]) {
                obj[attr] += 1;
            } else {
                obj[attr] = 1;
            }
            return obj;
        } else {
            return {[attr]: 1};
        }
    }

    function coalesceValueToZero(value: number | undefined | null) {
        if (value) {
            return value;
        } else {
            return 0;
        }
    }

    const handleFinishExam = () => {
        let totalQuestions = 0;
        let selectedQuestions = 0;
        let correctQuestions = 0;
        let maxScore = 0;
        let selectedMaxScore = 0;
        let score = 0;
        const categoryInfo = new Map();
        const specialtyInfo = new Map();
        const difficultyInfo = new Map();
        examData.cases.forEach((caseItem: CaseType) => {
            caseItem.questions.forEach((question) => {
                totalQuestions += 1;
                maxScore += difficultyScores.get(caseItem.difficulty) || 0;
                categoryInfo.set(caseItem.category, addOneToObjectAttribute(categoryInfo.get(caseItem.category), "count"));
                specialtyInfo.set(caseItem.specialty, addOneToObjectAttribute(specialtyInfo.get(caseItem.specialty), "count"));
                difficultyInfo.set(caseItem.difficulty, addOneToObjectAttribute(difficultyInfo.get(caseItem.difficulty), "count"));
                if (isQuestionCompleted(question)) {
                    selectedQuestions += 1;
                    selectedMaxScore += difficultyScores.get(caseItem.difficulty) || 0;
                    categoryInfo.set(caseItem.category, addOneToObjectAttribute(categoryInfo.get(caseItem.category), "selected"));
                    specialtyInfo.set(caseItem.specialty, addOneToObjectAttribute(specialtyInfo.get(caseItem.specialty), "selected"));
                    difficultyInfo.set(caseItem.difficulty, addOneToObjectAttribute(difficultyInfo.get(caseItem.difficulty), "selected"));
                    if (question.answers.every((answer) => answer.correct === answer.selected)) {
                        correctQuestions += 1;
                        score += difficultyScores.get(caseItem.difficulty) || 0;
                        categoryInfo.set(caseItem.category, addOneToObjectAttribute(categoryInfo.get(caseItem.category), "correct"));
                        specialtyInfo.set(caseItem.specialty, addOneToObjectAttribute(specialtyInfo.get(caseItem.specialty), "correct"));
                        difficultyInfo.set(caseItem.difficulty, addOneToObjectAttribute(difficultyInfo.get(caseItem.difficulty), "correct"));
                    }
                }
            })
        })

        const overall = [{
            count: totalQuestions,
            selected: selectedQuestions,
            correct: correctQuestions
        }]
        const category: any[] = []
        const specialty: any[] = []
        const difficulty: any[] = []
        categoryInfo.forEach((value: any, key: string) => {
            category.push({
                key: key,
                count: coalesceValueToZero(value.count),
                selected: coalesceValueToZero(value.selected),
                correct: coalesceValueToZero(value.correct)
            })
        })
        specialtyInfo.forEach((value: any, key: string) => {
            specialty.push({
                key: key,
                count: coalesceValueToZero(value.count),
                selected: coalesceValueToZero(value.selected),
                correct: coalesceValueToZero(value.correct)
            })
        })
        difficultyInfo.forEach((value: any, key: string) => {
            difficulty.push({
                key: key,
                count: coalesceValueToZero(value.count),
                selected: coalesceValueToZero(value.selected),
                correct: coalesceValueToZero(value.correct)
            })
        })
        const results = {
            overall: overall,
            category: category,
            specialty: specialty,
            difficulty: difficulty,
            max_score: maxScore,
            selected_max_score: selectedMaxScore,
            score: score,
            status: "Graded"
        }
        localStorage.setItem('resultsDemo', JSON.stringify(results));
        navigate('/results-demo');
    }

    React.useEffect(() => {
        const examData = demo;
        const newCases = examData.cases.map((caseItem: CaseType, index:number) => {
            let totalQuestions = 0;
            let answersSelected = 0;
            caseItem.questions.forEach((question) => {
                totalQuestions += 1;
                question.answers.forEach((answer) => {
                    if (answer.selected === undefined) {
                        answer.selected = false;
                    } else {
                        if (answer.selected === true) {
                            answersSelected += 1;
                        }
                    }
                    
                })
                if (isQuestionCompleted(question)) {
                    const newCompletedQuestions = completedQuestions;
                    newCompletedQuestions.add(question.id)
                    setCompletedQuestions(newCompletedQuestions);
                }
            })
            if (answersSelected === totalQuestions) {
                const newCompletedCases = completedCases;
                newCompletedCases.add(caseItem.id);
                setCompletedCases(newCompletedCases);
            }
            return caseItem;
        })
        setExamData({...examData, cases: newCases});
    }, []);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setTimeElapsed(timeElapsed + 1);
            if (examData.timeIntendedMilliseconds / 1000 - timeElapsed <= 0) {
                handleFinishExam();
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [timeElapsed]);

    const classicActions = [
        { icon: <ExitToAppIcon />, name: 'Salir sin guardar', onClick: () => handleExitExam() },
        { icon: <CheckIcon />, name: 'Finalizar', onClick: () => setFinishDialogOpen(true) },
    ];

    const defaultActions = [
        { icon: <GridOnIcon />, name: 'Navegar', onClick: () => setDrawerOpen(!drawerOpen) },
        { icon: <ExitToAppIcon />, name: 'Salir sin guardar', onClick: () => handleExitExam() },
        { icon: <CheckIcon />, name: 'Finalizar', onClick: () => setFinishDialogOpen(true) },
    ]

    if (!examData?.cases.length) {
        return (
            <div>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:'15%', width:'100%'}}>
                    <CircularProgress />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <SimulatorAppBar remainingTime={examData.timeIntendedMilliseconds / 1000 - timeElapsed} />
            <FinalizeExamDialog 
                open={finishDialogOpen} 
                handleClose={() => setFinishDialogOpen(false)} 
                handleFinalize={handleFinishExam} 
            />
            <SimulatorSpeedDial 
                actions={view === 'classic' ? classicActions : defaultActions} 
            />
            <MainSimulator
                classic={view === 'classic'}
                examData={examData}
                playlists={[]}
                currentCase={currentCase}
                handleChangeCase={handleChangeCase}
                handleAnswerSelected={handleAnswerSelected}
                handleFinishExam={handleFinishExam}
                markedCases={markedCases}
                markCase={markCase}
                unmarkCase={unmarkCase}
                caseRef={caseRef}
                drawerOpen={drawerOpen}
                timeElapsed={timeElapsed}
                completedCases={completedCases}
                completedQuestions={completedQuestions}
            />
        </div>
    )

}

export default SimulatorDemo;