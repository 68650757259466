import React from 'react';
import 'css-doodle';


const Doodle =  ({ seed }) => {
    //Replace Math.random() with seed
    const hex = (seed).toString(16).slice(0, 6);
    const doodleRule = `
    @grid: 1 / 8rem  8rem;
    background-size: 83px 135px;
    background-color: #fff;
    background-image: @doodle(
      background: rgba(96, 86, 158, @rand(.9));
      transition: .2s ease @rand(${hex});
      transform: rotate(@rand(${hex}));
      clip-path: polygon(
        @rand(100%) 0, 100% @rand(100%), 0 @rand(100%)
      );
    ); `
    return (
        <css-doodle seed={seed}>{ doodleRule }</css-doodle>
    )
}

export default Doodle;