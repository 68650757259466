import Gradient from 'rgt';

const Grade = (props) => {
    return (
        <h1>
            <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                {props.prompt}
            </Gradient>
        </h1>
    )
}

export default Grade;