import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

import { deepPurple, indigo } from '@mui/material/colors';



interface PricingCardProps {
    title?: string;
    price?: number;
    description?: string;
    discount?: number;
    priceLookupKey?: string;
    handleProductSelection: (priceLookupKey: string) => void;
}

const PricingCard = ({ title, price, description, discount, priceLookupKey, handleProductSelection }: PricingCardProps) => {
    const [loading, setLoading] = React.useState(false);    

    return (
        <Card sx={{ width: 300, height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: deepPurple[600], borderRadius: 4}}>
            <CardContent>
                <Typography variant='h3' sx={{ fontFamily: 'Playfair Display', color: 'white' }}>
                    {title}
                </Typography>

                <Typography variant='h4' sx={{ fontFamily: 'Roboto, sans-serif', color: 'white' }}>
                    {price} MXN / mes 
                </Typography>
                <Typography variant='body1' sx={{ fontFamily: 'Roboto, sans-serif', color: 'white' }}>
                    {description}
                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }}>
                <LoadingButton 
                    loading={loading}
                    size='large' 
                    variant='contained' 
                    sx={{ width: '60%', marginBottom: 2, color: 'black', backgroundColor: 'white', borderRadius: 5, fontFamily: 'Roboto, sans-serif', ":hover": {backgroundColor: 'white'} }}
                    onClick={() => {
                        setLoading(true);
                        handleProductSelection(priceLookupKey || '')
                    }}
                    >
                    Suscribirse
                </LoadingButton>
            </Box>
        </Card>
    )
}

export default PricingCard;
