import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import CaseWidget from './CaseWidget';

import { CaseType } from '../../types';

type CaseProps = {
    examData: any,
    playlists: any,
    addPlaylist?: (playlist: string) => void,
    addQuestionToPlaylist?: (playlist: number, question: number) => void,
    removeQuestionFromPlaylist?: (playlist: number, question: number) => void,
    currentCase: number,
    handleChangeCase: (newCase: number) => void,
    handleFinishExam: () => void,
    caseRef: React.RefObject<HTMLDivElement>
}

const Case = (props: CaseProps) => {
    const examData = props.examData;
    const playlists = props.playlists;
    const addPlaylist = props.addPlaylist;
    const addQuestionToPlaylist = props.addQuestionToPlaylist;
    const removeQuestionFromPlaylist = props.removeQuestionFromPlaylist;
    const currentCase = props.currentCase;
    const handleChangeCase = props.handleChangeCase;
    const handleFinishExam = props.handleFinishExam;
    const caseRef = props.caseRef;

    return (
        <Box sx={{ width:'100%', pl:'2%', pt:'2%', pr:'5%', minHeight:'85vh', maxHeight:'85vh', overflow:'scroll', overflowX:'hidden'}}>
            <CaseWidget caseData={examData.cases[currentCase]} 
                        playlists={playlists}
                        addPlaylist={addPlaylist}
                        addQuestionToPlaylist={addQuestionToPlaylist}
                        removeQuestionFromPlaylist={removeQuestionFromPlaylist}
                        caseRef={caseRef}/>
            
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', p:'2%'}}>
                {currentCase !== 0 ? 
                    <Button variant='contained' onClick={() => handleChangeCase(currentCase - 1)}>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
                            Anterior
                        </Typography>
                    </Button>
                :
                    <div/>
                }
                {currentCase === examData.cases.length - 1 ?
                    <Button variant='contained' onClick={() => handleFinishExam()}>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
                            Finalizar
                        </Typography>
                    </Button>
                :
                    <Button variant='contained' onClick={() => handleChangeCase(currentCase + 1)}>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
                            Siguiente
                        </Typography>
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default Case;