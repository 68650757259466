import * as React from 'react';

import '../App.css';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

interface LandingVideoProps {
    user: any;
    actionWithUser: () => void;
    actionWithoutUser: () => void;
}

const LandingVideo = (props: LandingVideoProps) => {

    return (
        <section className='landing-section'>
          <video autoPlay loop muted>
            <source src="https://truedatapublicassets.blob.core.windows.net/true-images/study-background-hd.mp4" type="video/mp4" />
          </video>
          <div className='landing-section-content'>
            <Card sx={{ padding: '2%', width: '40%', marginLeft: 'auto', marginRight: '10%', marginBottom: '10%'}}>
              <Typography variant='h2' sx={{ fontFamily: 'Playfair Display' }}>
                Prepárate para el ENARM
              </Typography>
              <Typography variant='h5' sx={{ fontFamily: 'Roboto' }}>
                Crea y guarda simulaciones de exámenes y estudia con los mejores casos clínicos
              </Typography>
              <br />
              {props.user ?
                <Button onClick={
                  () => {
                    props.actionWithUser();
                  }
                } variant='contained' size='large' sx={{ marginTop: '2%', backgroundColor: '#3f51b5', color: 'white', borderRadius: 0 }}>
                  Ir al portal
                </Button>
                :
                <Button onClick={
                  () => {
                    props.actionWithoutUser();
                  }
                } variant='contained' size='large' sx={{ marginTop: '2%', backgroundColor: '#3f51b5', color: 'white', borderRadius: 0 }}>
                  Regístrate Gratis
                </Button>
              }

            </Card>
          </div>
        </section>
    )
}

export default LandingVideo;