import * as React from 'react';

import ReactGA from 'react-ga4';

import GradientText from '../utils/GradientText';
import PrimaryAppBar from '../components/PrimaryAppBar';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import useAxios from '../utils/useAxios';

import { useNavigate } from 'react-router-dom';


const SimulatorCustom = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();

    const [totalCases, setTotalCases] = React.useState(10)
    const [difficultyProportions, setDifficultyProportions] = React.useState<number[]>([33, 67]);
    const [categoryProportions, setCategoryProportions] = React.useState<number[]>([33, 67])

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const handleDifficultyChange = (event: Event, newValue: number | number[]) => {
        setDifficultyProportions(newValue as number[]);
      };

    const handleCreateSimulator = async () => {
        try {
            const simulatorParams = {
                "number_of_cases": totalCases,
                "difficulties": {
                    'F': (difficultyProportions[0]) / 100,
                    'M': (difficultyProportions[1] - difficultyProportions[0]) / 100,
                    'D': (100 - difficultyProportions[1]) / 100
                },
                "categories": {
                    'SP': categoryProportions[0] / 100,
                    'MF': (categoryProportions[1] - categoryProportions[0]) / 100,
                    'U': (100 - categoryProportions[1]) / 100
                }
            }
            setLoading(true);
            const { data } = await axios.post('/exams/', simulatorParams)
            setLoading(false);
            navigate(`/simulator/${data[0].examId}`)
        } catch (error) {
            setLoading(false);
            alert('El servicio no está disponible en estos momentos. Intente más tarde.')
        }
    }

    return (
        <div>
            <PrimaryAppBar />
            {
                loading ? <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', width:'100%', mt:'5%'}}><CircularProgress /></Box> 
                :
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop: '5%'}}>
                    <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3%' }}>
                        <Stack sx={{ display:'flex', justifyContent: 'center', width:'100%' }} spacing={3}>
                            <Typography variant='h3' sx={{ fontFamily: 'Playfair Display', color: 'black'}}>
                                ENARM Personalizado
                            </Typography>
                            <Box sx={{ display:'flex', justifyContent: 'center', alignItems:'center', m:'2%'}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Número de casos</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={totalCases}
                                    label="Número de casos"
                                    onChange={(e) => setTotalCases(e.target.value as number)}
                                    >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                    Nivel de dificultad
                                </Typography>
                                <Slider
                                    getAriaLabel={() => 'Dificultad'}
                                    value={difficultyProportions}
                                    onChange={handleDifficultyChange}
                                    sx={{ width: '100%' }}
                                />
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: "space-around" }}>
                                    <Stack>
                                        <GradientText prompt={difficultyProportions[0] + "%"} from="#8fc4eb" to="#2483c9"/>
                                        <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                            Fácil
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <GradientText prompt={difficultyProportions[1] - difficultyProportions[0] + "%"} from="#ffe669" to="#ffd919"/>
                                        <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                            Medio
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <GradientText prompt={100 - difficultyProportions[1] + "%"} from="#ff7f7f" to="#ff0000"/>
                                        <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                            Difícil
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                    Categorías
                                </Typography>
                                <Slider
                                    getAriaLabel={() => 'Categorías'}
                                    value={categoryProportions}
                                    onChange={(event: Event, newValue: number | number[]) => {
                                        setCategoryProportions(newValue as number[]);
                                    }}
                                    sx={{ width: '100%' }}
                                />
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: "space-around" }}>
                                    <Stack>
                                        <GradientText prompt={categoryProportions[0] + "%"} from="#3b3b3b" to="#000000"/>
                                        <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                            Salud Pública
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <GradientText prompt={categoryProportions[1] - categoryProportions[0] + "%"} from="#3b3b3b" to="#000000"/>
                                        <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                            Medicina Familiar
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <GradientText prompt={100 - categoryProportions[1] + "%"} from="#3b3b3b" to="#000000"/>
                                        <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', color: 'black', mr:'2%'}}>
                                            Urgencias
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Box>
                            <Button color="secondary" variant='contained' sx={{fontFamily: 'Roboto Sans-Serif', marginTop: '5%'}}
                                    onClick={() => handleCreateSimulator() }>
                                Crear
                            </Button>
                        </Stack>
                    </Paper>
                </Box>
            }
        </div>
    )
}

export default SimulatorCustom

