import dayjs from '../utils/dayjs';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { Virtuoso } from 'react-virtuoso';
import SimulatorListItem from './SimulatorListItem';

import { useNavigate } from 'react-router-dom';

interface ActivityHistoryProps {
    simulators: any;
}

const ActivityHistory = (props: ActivityHistoryProps) => {
  const navigate = useNavigate();


  const simulators = props.simulators;

  const simulatorsByDate = {} as any;
  const simulatorsWithDates = [] as any;

  if (simulators.length === 0) {
    return (
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'60vh'}}>
        <Typography variant='h5' sx={{ color: 'black'}}>
            No tienes simuladores
        </Typography>
      </Box>      
    )
  }

  simulators.forEach((simulator:any) => {

    const date = dayjs(simulator.dateCreated['$date']).format('DD [de] MMMM [de] YYYY');
    if (!simulatorsByDate[date]) {
      simulatorsByDate[date] = [simulator];
      simulatorsWithDates.push({date: date, isHeader: true});
    }
    simulatorsByDate[date].push(simulator);
    simulatorsWithDates.push(simulator);
  });

  return (
    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%'}}>
      <Virtuoso
        style={{ height: '60vh', width:'80%', display:'flex', alignItems:'center', justifyContent:'center'}}
        data={simulatorsWithDates}
        itemContent={(index, simulator:any) => (
          simulator.isHeader ?
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'left', width:'100%', pb:'2%'}}>
              <Typography variant='h5'>
                {simulator.date}
              </Typography>
            </Box>
          :
            simulator.active ?
              <SimulatorListItem simulator={simulator} individualItemAction={{actionMessage: 'Reanudar', action: (id: number) => navigate(`/simulator/${id}`)}}/>
              :
              <SimulatorListItem simulator={simulator} individualItemAction={{actionMessage: 'Resultados', action: (id: number) => navigate(`/results/${id}`)}}/>
        )}/>     
    </Box>
  )
}

export default ActivityHistory;
