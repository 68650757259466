import * as React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

import Dialog from '@mui/material/Dialog';

import { difficultyScores } from '../../utils/constants';

import { CaseType } from '../../types';

type CaseWidgetProps = {
    caseData : any,
    playlists: any,
    addPlaylist?: (playlist: string) => void,
    addQuestionToPlaylist?: (playlist: number, question: number) => void,
    removeQuestionFromPlaylist?: (playlist: number, question: number) => void,
    caseRef: React.RefObject<HTMLDivElement>
}

const CaseWidget = (props: CaseWidgetProps) => {
    const caseData = props.caseData;
    const playlists = props.playlists;
    const addPlaylist = props.addPlaylist;
    const addQuestionToPlaylist = props.addQuestionToPlaylist;
    const removeQuestionFromPlaylist = props.removeQuestionFromPlaylist;
    const caseRef = props.caseRef;

    const correctColor = '#4CBB17';
    const incorrectColor = '#E74C3C';

    const [newPlaylist, setNewPlaylist] = React.useState('');

    const answerNumberToLetter = { 0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H', 8: 'I', 9: 'J' } as any;
    return (
        <Box ref={caseRef}>
            <Paper elevation={5} sx={{width:'100%', display:'flex', alignItems:'center', p:'2%', mb:'2%', bgcolor: 'white'}}>
                <Typography variant='subtitle1' sx={{ color: 'black', textAlign:'left'}}>
                    {caseData.prompt}
                </Typography>
            </Paper>
            <Stack sx={{width:'100%', display:'flex', justifyContent:'center', alignItems:'left', p:'0.5%', mt:'2%'}}>
                <Typography variant='subtitle1' textAlign='left' sx={{ color: 'black' }}>
                    Explicación:
                </Typography>
                <Typography variant='subtitle1' textAlign='left' sx={{ color: 'black', borderTop:'1px solid'}}>
                    {caseData.explanation}
                </Typography>
            </Stack>
            {caseData.questions.map((question:any, qIndex:number) => (
                <Paper key={qIndex} elevation={0} sx={{width:'95%', p:'2%', mb:'2%', bgcolor: 'transparent'}}>
                    <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', mb:'2%'}}>
                      <Box sx={{width:'100%', display:'flex', alignItems:'center'}}>
                          <Typography variant='subtitle1' sx={{ color: 'black' }} textAlign='left'>
                              {qIndex+1}. {question.prompt}
                          </Typography>
                          <Dialog open={question.clicked ? question.clicked : false } onClose={() => question.clicked = false}>
                              <Stack sx={{width:'30vw', display:'flex', alignItems:'center', p:'5%'}}>
                                  <Box sx={{width:'100%', display:'flex', alignItems:'center', p:'2%'}}>
                                      <Typography variant='subtitle1' sx={{ color: 'black'}}>
                                          Agregar pregunta a la lista de reproducción:
                                      </Typography>
                                  </Box>
                                  <Stack sx={{width:'100%', display:'flex', p:'2%'}}>
                                      <Box sx={{width:'60%', display:'flex', alignItems:'flex-end'}}>
                                          <Input type='text' placeholder='Nueva playlist' sx={{width:'100%'}} onChange={(e) => setNewPlaylist(e.target.value)}
                                                endAdornment={
                                                      addPlaylist && 
                                                      <InputAdornment position="end">
                                                          <IconButton onClick={() => addPlaylist(newPlaylist)}>
                                                              <AddCircleOutlineIcon/>
                                                          </IconButton>
                                                      </InputAdornment>
                                                  }
                                          />  
                                      </Box>
                                      
                                      {playlists.map((playlist:any, pIndex:number) => (
                                          <Box key={pIndex} sx={{width:'100%', display:'flex', alignItems:'center', p:'2%'}}>
                                              <Box sx={{width:'60%', display:'flex', alignItems:'center', p:'2%', border:'1px solid gray', borderRadius:'5px'}}>
                                                  <Typography variant='subtitle1' sx={{ color: 'black'}}>
                                                      {playlist.name}
                                                  </Typography>
                                              </Box>
                                              {
                                                  addQuestionToPlaylist && removeQuestionFromPlaylist &&
                                                  <Box sx={{width:'10%', display:'flex', alignItems:'center', p:'1%'}}>
                                                      {playlist.questions.map((q:any, qIndex:number) => (
                                                          q.id
                                                      )).includes(question.id) ?
                                                          <IconButton onClick={() => removeQuestionFromPlaylist(playlist.id, question.id)}>
                                                              <BookmarkIcon/>
                                                          </IconButton>
                                                          :
                                                          <IconButton onClick={() => addQuestionToPlaylist(playlist.id, question.id)}>
                                                              <BookmarkBorderIcon/>
                                                          </IconButton>
                                                      }
                                                  </Box>
                                              }
                                          </Box>
                                      ))}
                                  </Stack>
                              </Stack>
                          </Dialog>
                          {
                              addQuestionToPlaylist && removeQuestionFromPlaylist && 
                              <IconButton onClick={() => question.clicked ? question.clicked = false : question.clicked = true}>
                                  {
                                      playlists.map((playlist:any, pIndex:number) => (
                                          playlist.questions.map((q:any, qIndex:number) => (
                                              q.id
                                          ))
                                      )).flat().includes(question.id) ?
                                      <StarIcon fontSize="medium"/> :
                                      <StarBorderIcon fontSize="medium"/>
                                  }
                              </IconButton>
                          }
                      </Box>
                      <Box sx={{display:'flex', alignItems:'center', justifyContent:'left'}}>
                        <Typography variant='subtitle1' sx={{ color: question.answers.every((answer:any) => answer.correct) ? correctColor: question.answers.every((answer:any) => !answer.correct === answer.selected) ? incorrectColor: 'transparent', fontWeight:'bold' }} textAlign='left' noWrap>
                            {question.answers.every((answer:any) => answer.correct === answer.selected) ? `${difficultyScores.get(caseData.difficulty)}/${difficultyScores.get(caseData.difficulty)} pts` : `0/${difficultyScores.get(caseData.difficulty)} pts`}
                        </Typography>
                      </Box>
                    </Box>
                    {question.answers.map((answer:any, aIndex:number) => (
                        <Box key={aIndex} sx={{width:'100%', display:'flex', alignItems:'center', p:'0.5%', borderRadius: '1rem', bgcolor: answer.correct ? correctColor : answer.selected ? incorrectColor : 'transparent'  }}> 
                            <Stack direction='row' spacing='1%' sx={{width:'100%', display:'flex', alignItems:'center'}}>
                                <Checkbox
                                checked={caseData.questions[qIndex].answers[aIndex].selected || caseData.questions[qIndex].answers[aIndex].correct}
                                disabled={true}
                                icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                                checkedIcon={answer.correct ? <CheckCircleIcon fontSize="medium" style={{ color: 'white' }} /> : <CancelIcon fontSize="medium" style={{ color: 'white' }} />}
                                disableRipple={true}
                                />
                                <Typography variant='subtitle1' sx={{ color: answer.selected || answer.correct ? 'white': 'black', p:'0.5%', borderRadius: '1rem' }}>
                                    {answerNumberToLetter[aIndex]}{')'} {answer.prompt}
                                </Typography>
                            </Stack>
                        </Box>
                    ))}
                </Paper>
            ))}
        </Box>
    );
}
                
export default CaseWidget;