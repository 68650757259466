import * as React from 'react';

import { makeStyles, createStyles, Theme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

import { createTheme } from '@mui/system';

interface ProfileDataProps {
    firstName?: string;
    lastName?: string;
    email?: string;
}

const ProfileData = (props: ProfileDataProps) => {

    return (
        <Box>
            <Stack direction="row" spacing={2} sx={{ display:'flex', alignItems:'center'}}>
                <Avatar sx={{cursor: 'pointer', width:'7rem', height:'7rem'}}/>
                <Stack sx={{ display:'flex'}}>
                    <Typography variant="h4" textAlign='left' sx={{fontWeight: 'bold' }}>
                        {props.firstName} {props.lastName}
                    </Typography>
                    <Typography variant="body1" textAlign='left'>
                        {props.email}
                    </Typography>
                    <Link href="/profile" variant="body1" textAlign='left' underline="none" sx={{ '&:hover': { color: '#3f51b5' } }}>
                        Detalles de la cuenta
                    </Link>
                </Stack>
            </Stack>
        </Box>
    )
}

export default ProfileData