import * as React from 'react';

import ReactGA from 'react-ga4';

import { useNavigate } from 'react-router-dom';
import useAxios from '../utils/useAxios';
import AuthContext from '../context/AuthContext';

import PrimaryAppBar from "../components/PrimaryAppBar";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";

import { products } from "../utils/constants";

const Subscribe = () => {
    const navigate = useNavigate();
    const { axios } = useAxios();
    
    const { user } = React.useContext(AuthContext);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const handleProductSelection = async (lookup_key: string) => {
        if (user) {
          await axios.post('/create-checkout-session/', {
            lookup_key: lookup_key
          })
            .then((response: any) => {
              window.location.href = response.data.url;
            }
          );
        } else {
          navigate('/register?lookup_key=' +  lookup_key);
        }
    }

    return (
        <div>
        <PrimaryAppBar />
        <Pricing 
            products={products}
            handleProductSelection={handleProductSelection}
        />
        <Footer />
        </div>
    );
};

export default Subscribe;