import * as React from 'react';

import useAxios from '../../utils/useAxios';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

import Dialog from '@mui/material/Dialog';

import { CaseType } from '../../types';

type CaseWidgetProps = {
    caseData : any,
    playlists: any,
    hidePlaylists?: boolean,
    addPlaylist?: (playlist: string) => void,
    addQuestionToPlaylist?: (playlist: number, question: number) => void,
    removeQuestionFromPlaylist?: (playlist: number, question: number) => void,
    handleAnswerSelected?: (selectedCase:CaseType, qIndex: number, aIndex: number) => void,
}


const CaseWidget = (props: CaseWidgetProps) => {
    const { axios, initialized } = useAxios();

    const caseData = props.caseData;
    const playlists = props.playlists;
    const addPlaylist = props.addPlaylist;
    const addQuestionToPlaylist = props.addQuestionToPlaylist;
    const removeQuestionFromPlaylist = props.removeQuestionFromPlaylist;
    const handleAnswerSelected = props.handleAnswerSelected;

    const [newPlaylist, setNewPlaylist] = React.useState('');

    const [image, setImage] = React.useState('');

    const answerNumberToLetter = { 0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H', 8: 'I', 9: 'J' } as any;

    React.useEffect(() => {
        const getImageUrl = async () => {
            try {
                const { data } = await axios.get(`/cases/${caseData.id}`);
                setImage(data.image);
            } catch (e) {
                console.log(e);
            }
        }
        if (initialized) getImageUrl();
    }, [caseData.id, axios, initialized]);

    return (
        <Box>
            <Stack spacing='3%' sx={{width:'100%', display:'flex', justifyContent:'center', p:'2%', bgcolor: 'white'}}>
                <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Typography variant='body1' sx={{ color: 'black', textAlign:'center'}}>
                        CASO CLÍNICO
                    </Typography>
                </Box>
                <Typography variant='subtitle1' sx={{ color: 'black', textAlign:'left'}}>
                    {caseData.prompt}
                </Typography>
                {
                    image &&
                    <img src={image} alt='case' style={{width:'100%'}}/>
                }
            </Stack>
            {caseData.questions.map((question:any, qIndex:number) => (
                <Paper key={qIndex} elevation={0} sx={{width:'95%', p:'2%', mb:'2%', bgcolor: 'transparent'}}>
                    <Box sx={{width:'100%', display:'flex'}}>
                        <Stack direction='row' spacing='1%' sx={{width:'100%', display:'flex'}}>
                            <Box>
                                <Box sx={{bgcolor:'red'}}>
                                    <Typography variant='subtitle1' sx={{ color: 'black'}} textAlign='left'>
                                        PREGUNTA {qIndex+1}. 
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant='subtitle1' sx={{ color: 'black'}} textAlign='left'>
                                    {question.prompt}
                                </Typography>
                            </Box>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                {
                                    addQuestionToPlaylist && removeQuestionFromPlaylist &&
                                    <IconButton onClick={() => question.clicked ? question.clicked = false : question.clicked = true}>
                                        {
                                            playlists.map((playlist:any, pIndex:number) => (
                                                playlist.questions.map((q:any, qIndex:number) => (
                                                    q.id
                                                ))
                                            )).flat().includes(question.id) ?
                                            <StarIcon fontSize="medium"/> :
                                            <StarBorderIcon fontSize="medium"/>
                                        }
                                    </IconButton>
                                }
                            </Box>
                        </Stack>
                        <Dialog open={question.clicked ? question.clicked : false } onClose={() => question.clicked = false}>
                            <Stack sx={{width:'30vw', display:'flex', alignItems:'center', p:'5%'}}>
                                <Box sx={{width:'100%', display:'flex', alignItems:'center', p:'2%'}}>
                                    <Typography variant='subtitle1' sx={{ color: 'black'}}>
                                        Agregar pregunta a la lista de reproducción:
                                    </Typography>
                                </Box>
                                <Stack sx={{width:'100%', display:'flex', p:'2%'}}>
                                    <Box sx={{width:'60%', display:'flex', alignItems:'flex-end'}}>
                                        <Input type='text' placeholder='Nueva playlist' sx={{width:'100%'}} onChange={(e) => setNewPlaylist(e.target.value)}
                                            endAdornment={
                                                    <InputAdornment position="end">
                                                        {
                                                            addPlaylist && 
                                                            <IconButton onClick={() => addPlaylist(newPlaylist)}>
                                                                <AddCircleOutlineIcon/>
                                                            </IconButton>
                                                        }
                                                    </InputAdornment>
                                                }
                                        />  
                                    </Box>
                                    
                                    {playlists.map((playlist:any, pIndex:number) => (
                                        <Box key={pIndex} sx={{width:'100%', display:'flex', alignItems:'center', p:'2%'}}>
                                            <Box sx={{width:'60%', display:'flex', alignItems:'center', p:'2%', border:'1px solid gray', borderRadius:'5px'}}>
                                                <Typography variant='subtitle1' sx={{ color: 'black'}}>
                                                    {playlist.name}
                                                </Typography>
                                            </Box>
                                            {
                                                addQuestionToPlaylist && removeQuestionFromPlaylist &&
                                                <Box sx={{width:'10%', display:'flex', alignItems:'center', p:'1%'}}>
                                                    {playlist.questions.map((q:any, qIndex:number) => (
                                                        q.id
                                                    )).includes(question.id) ?
                                                        <IconButton onClick={() => removeQuestionFromPlaylist(playlist.id, question.id)}>
                                                            <BookmarkIcon/>
                                                        </IconButton>
                                                        :
                                                        <IconButton onClick={() => addQuestionToPlaylist(playlist.id, question.id)}>
                                                            <BookmarkBorderIcon/>
                                                        </IconButton>
                                                    }
                                                </Box>
                                            }
                                        </Box>
                                    ))}
                                </Stack>
                            </Stack>
                        </Dialog>
                    </Box>
                    {question.answers.map((answer:any, aIndex:number) => (
                        <Box key={aIndex} sx={{width:'100%', display:'flex', alignItems:'center', p:'0.5%'}}>
                            <Stack direction='row' sx={{width:'100%', display:'flex', alignItems:'center'}}>
                                {
                                    handleAnswerSelected &&
                                    <IconButton onClick={() => handleAnswerSelected(caseData, qIndex, aIndex)} sx={{'&:hover': {backgroundColor: 'transparent'}}}>
                                        <Typography variant='subtitle1' sx={{ color: 'black', fontWeight: caseData.questions[qIndex].answers[aIndex].selected ? 'bold' : 'normal'}}>
                                            {answerNumberToLetter[aIndex].toLowerCase()}{')*'}
                                        </Typography>
                                    </IconButton>
                                }
                                <Typography variant='subtitle1' sx={{ color: 'black', fontWeight: caseData.questions[qIndex].answers[aIndex].selected ? 'bold' : 'normal'}} textAlign='left'>
                                    {answer.prompt}
                                </Typography>
                            </Stack>
                        </Box>
                    ))}
                </Paper>
            ))}
        </Box>
    );
}
                
export default CaseWidget;