import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

interface FeatureCardProps {
    title: string;
    description: string;
}

const FeatureCard = ({ title, description }: FeatureCardProps) => {
    return (
        <Card sx={{ padding: '2%', width: '20rem', height: '15rem', backgroundColor: '#FFF88', borderRadius: '0.5rem' , boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)', justifyContent: 'center', display: 'flex' }}>
            <Stack direction='column' spacing={2} sx={{ justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant='h4' sx={{ fontFamily: 'Playfair Display' }}>
                    {title}
                </Typography>
                <Typography variant='h5' sx={{ fontFamily: 'Roboto, sans-serif' }}>
                    {description}
                </Typography>
            </Stack>
        </Card>
    );
};

export default FeatureCard;