import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import CaseWidget from './CaseWidget';
import dayjs from '../../utils/dayjs';

import { Virtuoso } from 'react-virtuoso';

import { CaseType } from '../../types';

type CaseProps = {
    examData: any,
    playlists: any,
    hidePlaylists?: boolean,
    addPlaylist?: (playlist: string) => void,
    addQuestionToPlaylist?: (playlist: number, question: number) => void,
    removeQuestionFromPlaylist?: (playlist: number, question: number) => void,
    handleAnswerSelected: (selectedCase: CaseType, qIndex: number, aIndex: number) => void,
    handleFinishExam: () => void,
    markedCases: Array<number>,
    markCase: (caseIndex: number) => void,
    unmarkCase: (caseIndex: number) => void,
}

const Case = (props: CaseProps) => {
    const examData = props.examData;
    const playlists = props.playlists;
    const addPlaylist = props.addPlaylist;
    const addQuestionToPlaylist = props.addQuestionToPlaylist;
    const removeQuestionFromPlaylist = props.removeQuestionFromPlaylist;
    const handleAnswerSelected = props.handleAnswerSelected;
    const handleFinishExam = props.handleFinishExam;
    const markedCases = props.markedCases;
    const markCase = props.markCase;
    const unmarkCase = props.unmarkCase;

    const virtuosoRef = React.useRef<any>(null)

    const [showMarkedCases, setShowMarkedCases] = React.useState<boolean>(false);

    const convertToRoman = (year: number): string => {
        // Define arrays to store Roman numeral symbols and their corresponding values
        const romanNumerals: string[] = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
        const romanValues: number[] = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
        
        // Initialize an empty string to hold the Roman numeral representation of the year
        let romanYear: string = "";
        
        // Loop through the Roman numeral symbols and their values
        for (let i: number = 0; i < romanNumerals.length; i++) {
          // While the year is greater than or equal to the current Roman numeral value
          while (year >= romanValues[i]) {
            // Add the current Roman numeral symbol to the Roman year string
            romanYear += romanNumerals[i];
            // Subtract the current Roman numeral value from the year
            year -= romanValues[i];
          }
        }
        
        // Return the Roman numeral representation of the year
        return romanYear;
    } 

    return(
        <Box sx={{ width:'100%'}}>
            <Virtuoso
                ref={virtuosoRef}
                style={{ height: '85vh' }}
                data={[null, ...examData.cases, null]}
                itemContent={(index, caseItem) => (
                    index === 0 ?
                        <Stack sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mb:'3%', mt:'3%'}}>
                            <Typography variant="h3" component="div" sx={{ flexGrow: 1, color: '#97233F' }}>
                                ENARM {dayjs().year()} {convertToRoman(dayjs().year() - 1976)} {/* Assuming that the ENARM is applied every year */}
                            </Typography>
                            <Button onClick={() => setShowMarkedCases(!showMarkedCases)}>
                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, color: 'black' }}>
                                    {showMarkedCases ? 'OCULTAR' : 'MOSTRAR'}  CASOS CLÍNICOS MARCADOS
                                </Typography>
                            </Button>
                            <Stack direction="row" spacing={1} sx={{ pt:'1%'}}>
                            {showMarkedCases && markedCases.length > 0 ?
                                markedCases.map((caseIndex: number) => (
                                    <Button onClick={() => virtuosoRef.current?.scrollToIndex(caseIndex + 1)} size="small" variant="outlined" key={caseIndex}>
                                        {caseIndex + 1}
                                    </Button>
                                ))
                                : null
                            }
                            </Stack>
                        </Stack>
                    :
                    index === examData.cases.length + 1 ?
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', p:'2%'}}>
                            <Button variant='contained' onClick={() => handleFinishExam()}>
                                <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
                                    Finalizar
                                </Typography>
                            </Button>
                        </Box>
                    :
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                            <Box sx={{width:'90%', p:'0.5%'}}>
                                <CaseWidget key={index} 
                                            caseData={caseItem} 
                                            handleAnswerSelected={handleAnswerSelected} 
                                            playlists={playlists}
                                            addPlaylist={addPlaylist}
                                            addQuestionToPlaylist={addQuestionToPlaylist}
                                            removeQuestionFromPlaylist={removeQuestionFromPlaylist}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'space-between', justifyContent: 'space-between'}}>
                                    <Typography variant="body2">
                                        FIN DEL CASO CLÍNICO
                                    </Typography>
                                    <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center'}}>
                                        <Typography variant="body2">
                                            MARCAR CASO
                                        </Typography>
                                        <Checkbox
                                            checked={markedCases.includes(index - 1)}
                                            onChange={() => markedCases.includes(index - 1) ? unmarkCase(index - 1) : markCase(index - 1)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Stack>
                                </Box>
                                <hr />
                            </Box>
                        </Box>
                )}
            ></Virtuoso>
        </Box>
    )
}

export default Case;