import * as React from 'react';


import rightarrowenarm from '../rightarrowenarm.svg';

import FeatureCard from './FeatureCard';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Typed from 'react-typed';

const WhyUs = () => {
    const whys = ['Siempre actualizado', 'Sencillo', 'Moderno', 'Personalizado'];

    return (
        <section className='why-us-section'>
            <div className='why-us-section-content'>
                <Stack>
                    <Stack direction='row' spacing={2} sx={{ marginBottom: '2%', justifyContent: 'center', alignItems: 'center', marginLeft: '10%', marginRight: 'auto'}}>
                        <Typography variant='h3' sx={{ fontFamily: 'Playfair Display' }}>
                            ¿Por qué nosotros?
                        </Typography>
                        <img src={rightarrowenarm} alt='right-arrow' style={{ width: '2rem'}}/>
                        <Typed
                            style={{ fontFamily: 'Roboto, sans-serif', fontSize: '2.2rem' }}
                            strings={whys}
                            typeSpeed={50}
                            backSpeed={50}
                            loop
                        />
                    </Stack>
                    
                    <br />
                    <Box sx={{ mx: 10 }}>
                        <Typography variant='h5' sx={{ fontFamily: 'Roboto, sans-serif' }}>
                            Simulamos el ENARM con la mayor precisión posible, para que puedas prepararte de la mejor manera.	
                        </Typography>
                    </Box>
                    <br/>
                    <Stack direction='row' spacing={4} sx={{ justifyContent: 'center', alignItems: 'center', marginLeft: '10%', marginRight: 'auto'}}>
                        <FeatureCard title='+ 1000 Casos' description='Estudia con los casos más recientes y variados' />
                        <FeatureCard title='Realista' description='Simula ENARM de 200 casos, temporizado, de los temas que necesitas' />
                        <FeatureCard title='Ilimitado' description='Haz cuantas simulaciones quieras y obtén calificaciones' />
                    </Stack>    
                </Stack>
            </div>
        </section>
    )
}

export default WhyUs;