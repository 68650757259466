
import CircularProgressWithLabel from './CircularProgressWithLabel';

interface SimulatorScoreProps {
    score: number;
    maxScore: number;
    backgroundColor?: string,
    size?: number,
};

const SimulatorScore = (props: SimulatorScoreProps) => {
    const progress = props.score / props.maxScore;
    const label = (progress*100)?.toFixed(0).toString() + '%';
    return (
        <CircularProgressWithLabel progress={progress} label={label} backgroundColor={props.backgroundColor} size={props.size}/>
    );
}

export default SimulatorScore;