import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import UserContext from "../context/UserContext";

export function ProtectedRoute({ children }: { children: any }) {
  const { user } = useContext(AuthContext);
  

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

export function RegisterRoute({ children }: { children: any }) {
  const { user } = useContext(AuthContext);
  if (!user) {
    
    return <Navigate to="/register" replace />;
  }
  return children;
}
export function ContributorRoute({ children }: { children: any }) {
  const { user } = useContext(AuthContext);
  const { profile } = useContext(UserContext);
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  if (profile?.is_contributor === false) {
    return <Navigate to="/home" replace />;
  }
  return children;
}