import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

interface SimulatorSpeeedDialProps {
    actions: any[];
}

const SimulatorSpeeedDial = (props: SimulatorSpeeedDialProps) => {
    return (
        <div>
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'right', width:'100%'}}>
                <SpeedDial
                    ariaLabel='SpeedDial'
                    direction='down'
                    icon={<SpeedDialIcon />}
                    sx={{ top:'10%', right:'1%', position:'absolute', '& .MuiFab-primary': { width: '5vh', height: '5vh' } }}
                    >
                        {props.actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.onClick}
                            />
                        ))}
                </SpeedDial>
            </Box>
        </div>
    )
}

export default SimulatorSpeeedDial