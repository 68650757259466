import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import Slide from '@mui/material/Slide';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CheckIcon from '@mui/icons-material/Check';
import GridOnIcon from '@mui/icons-material/GridOn';

import FeedbackAppBar from '../components/FeedbackAppBar';
import FinalizeExamDialog from '../components/FinalizeExamDialog';
import SimulatorSpeedDial from '../components/SimulatorSpeedDial';
import FeedbackSimulator from '../components/FeedbackSimulator';

import EnarmClassicCase from '../components/EnarmClassic/Case';

import EnarmDefaultCase from '../components/EnarmDefault/Case';
import EnarmDefaultNavigator from '../components/EnarmDefault/Navigator';

import { CaseType, QuestionType } from '../types';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import useAxios from '../utils/useAxios';

const ExamFeedback = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [queryParams, setQueryParams] = useSearchParams();

    const view = queryParams.get('view');

    const [examData, setExamData] = React.useState<any>(null);
    const [playlists, setPlaylists] = React.useState<Array<any>>([]);
    const [markedCases, setMarkedCases] = React.useState<Array<number>>([]);

    const [correctCases, setCorrectCases] = React.useState<Set<string>>(new Set());
    const [correctQuestions, setCorrectQuestions] = React.useState<Set<string>>(new Set());
    const [currentCase, setCurrentCase] = React.useState<number>(0);
    const [timeElapsed, setTimeElapsed] = React.useState<number>(0);

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(true);
    const [finishDialogOpen, setFinishDialogOpen] = React.useState<boolean>(false);

    const caseRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const fetchCases = async () => {
            try {
                const response = await axios.get(`exams/${id}/`, {params: { cases: true }});
                const data = response.data[0];

                const cases = data.cases.map((caseItem: CaseType, index:number) => {
                    let totalQuestions = 0;
                    let answersSelected = 0;
                    caseItem.questions.forEach((question) => {
                        totalQuestions += 1;
                        question.answers.forEach((answer) => {
                            if (answer.selected === undefined) {
                                answer.selected = false;
                            } else {
                                if (answer.selected === true) {
                                    answersSelected += 1;
                                }
                            }
                            
                        })
                        if (isQuestionCorrect(question)) {
                            const newCorrectQuestions = correctQuestions;
                            newCorrectQuestions.add(question.id)
                            setCorrectQuestions(newCorrectQuestions);
                        }
                    })
                    if (isCaseCorrect(caseItem)) {
                        const newCorrectCases = correctCases;
                        newCorrectCases.add(caseItem.id)
                        setCorrectCases(newCorrectCases);
                    }
                    return caseItem;
                })
                setExamData({...data, cases: cases});
            } catch (error) {
                console.log(error);
            }
        }       
        const fetchPlaylists = async () => {
            try {
                const response = await axios.get(`playlists/`);
                const data = response.data;
                setPlaylists(data);
            } catch (error) {
                console.log(error);
            }
        }
        if (initialized) {
            fetchCases();
            fetchPlaylists();
        }
    }, [id, initialized]);

    const addPlaylist = async (name: string) => {
        try {
            const response = await axios.post("playlists/", {name: name});
            const newPlaylist = response.data;
            if (response.status === 201) {
                setPlaylists([...playlists, newPlaylist]);
            } else {
                console.log("Something went wrong");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addQuestionToPlaylist = async (playlistId: number, questionId: number) => {
        try {
            const response = await axios.post("add-question-to-playlist/", {playlist: playlistId, question: questionId});
            const updatedPlaylist = response.data;
            if (response.status === 201) {
                const newPlaylists = playlists.map((playlist) => {
                    if (playlist.id === updatedPlaylist.id) {
                        return {...playlist, questions: updatedPlaylist.questions};
                    } else {
                        return playlist;
                    }
                })
                setPlaylists(newPlaylists);
            } else {
                console.log("Something went wrong");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const removeQuestionFromPlaylist = async (playlistId: number, questionId: number) => {
        try {
            const response = await axios.delete("remove-question-from-playlist/", {data: {playlist: playlistId, question: questionId}});
            const updatedPlaylist = response.data;
            if (response.status === 200) {
                const newPlaylists = playlists.map((playlist) => {
                    if (playlist.id === updatedPlaylist.id) {
                        return {...playlist, questions: updatedPlaylist.questions};
                    } else {
                        return playlist;
                    }
                })
                setPlaylists(newPlaylists);
            } else {
                console.log("Something went wrong");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const isQuestionCorrect = (QuestionItem: QuestionType) => {
        const answers = QuestionItem.answers;
        return answers.every((answer:any) => answer.correct === answer.selected);
    }

    const isCaseCorrect = (CaseItem: CaseType) => {
        const questions = CaseItem.questions;
        return questions.every((question) => isQuestionCorrect(question));
    }

    const handleChangeCase = (newCase: number) => {
        setCurrentCase(newCase)
        caseRef.current?.scrollIntoView({behavior: "smooth", block: "start"})
    }
    
    const handleFinishExam = async () => {
        const examId = examData._id as any;
        const timeElapsedMilliseconds = timeElapsed * 1000;
        try {
            await axios.put(`exams/${examId["$oid"]}/`, {"timeElapsedMilliseconds":timeElapsedMilliseconds})
        } catch (error) {
            console.log(error);
        }
        navigate(`/results/${id}`);
    }


    if (!examData?.cases.length) {
        return (
            <div>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:'15%', width:'100%'}}>
                    <CircularProgress />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <FeedbackAppBar/>
            <FinalizeExamDialog 
                open={finishDialogOpen} 
                handleClose={() => setFinishDialogOpen(false)} 
                handleFinalize={handleFinishExam} 
            />

            <FeedbackSimulator
                examData={examData}
                playlists={playlists}
                addPlaylist={addPlaylist}
                addQuestionToPlaylist={addQuestionToPlaylist}
                removeQuestionFromPlaylist={removeQuestionFromPlaylist}
                currentCase={currentCase}
                handleChangeCase={handleChangeCase}
                handleFinishExam={handleFinishExam}
                caseRef={caseRef}
                drawerOpen={drawerOpen}
                timeElapsed={timeElapsed}
                correctCases={correctCases}
                correctQuestions={correctQuestions}
            />
        </div>
      )
}

export default ExamFeedback