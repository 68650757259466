import * as React from 'react';

import SubscriptionDetail from './SubscriptionDetail';

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


interface SettingsProps {
    firstName?: string;
    lastName?: string;
    verified: boolean;
    stripe_subscription_status: string;
    stripe_subscription_current_period_end: string;
    showSubscriptionDetail: () => void;
}

const Settings = (props: SettingsProps) => {
    return (
        <div>
            <Box sx={{ marginTop: '0%'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '2%' }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: '2%',
                        marginRight: '2%'
                    }}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h5" >
                                {props.firstName} {props.lastName}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={4}>
                            <div>
                                {props.verified ? (
                                    <>
                                    <CheckCircleOutlineIcon color="primary"/>
                                    <Typography color="primary">Verificado</Typography>
                                    </>
                                ) : (
                                    <>
                                    <ErrorOutlineIcon color="error" />
                                    <Typography color="error">No verificado</Typography>
                                    </>
                                )}
                            </div>
                            <div>
                                {props.stripe_subscription_status == "active" ? (
                                    <>
                                    <CheckCircleOutlineIcon color="primary"/>
                                    <Typography color="primary">Suscrito</Typography>
                                    </>
                                ) : (
                                    <>
                                    <ErrorOutlineIcon color="error"/>
                                    <Typography color="error">No suscrito</Typography>
                                    </>
                                )}
                            </div>
                        </Stack>
                    </Box>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '2%' }}>
                    <SubscriptionDetail
                        status={props.stripe_subscription_status}
                        current_period_end={props.stripe_subscription_current_period_end}
                        showSubscriptionDetail={props.showSubscriptionDetail}
                    />
                </Box>
            </Box>
        </div>
    )

}

export default Settings