import * as React from 'react';

import AuthContext from '../context/AuthContext';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AutoComplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import useAxios from '../utils/useAxios';

import { CaseType, TagType } from '../types';
import { difficulties, categories, specialties, english } from '../utils/constants';


const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1},
    { field: 'prompt', headerName: 'Caso', flex: 5 },
    {
      field: 'difficulty',
      headerName: 'Dificultad',
      flex: 2,
    },
    {
        field: 'category',
        headerName: 'Categoría',
        flex: 2,
    },
    {
        field: 'specialty',
        headerName: 'Especialidad',
        flex: 2,
    },
    {
        field: 'english',
        headerName: 'Inglés',
        flex: 2,
    }
  ];

const Cases = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();

    const { user, logoutUser } = React.useContext(AuthContext);

    const [cases, setCases] = React.useState<CaseType[]>([]);
    const [tags, setTags] = React.useState<TagType[]>([]);
    const [tagRerender, setTagRerender] = React.useState<boolean>(false);

    const [queryTags, setQueryTags] = React.useState<string[]>([]);

    React.useEffect(() => {
        const getCases = async () => {
            const response = await axios.get('/cases', {params: { tags: queryTags }});
            const cases = response.data.map((myCase:CaseType) => {
                return {
                    ...myCase,
                    difficulty: difficulties.map.get(myCase.difficulty),
                    category: categories.map.get(myCase.category),
                    specialty: specialties.map.get(myCase.specialty),
                    english: english.map.get(myCase.english),
                }
            })
            setCases(cases);
        };
        const getTags = async () => {
            const response = await axios.get('/tags');
            setTags(response.data);
        };
        if (initialized) {
            getCases();
            getTags();
        }
    }, [initialized, queryTags])


    return (
        <div>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                <Typography variant="h6" color="inherit" noWrap>
                    ENARM PLUS
                </Typography>
                {user && (
                    <Button
                    color="primary"
                    variant="outlined"
                    sx={{ marginRight: 0, marginLeft: 'auto' }}
                    onClick={() => logoutUser()}
                    >
                    Logout
                    </Button>
                )}
                </Toolbar>
            </AppBar>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
                <div style={{ height: 400, width: '80%' }}>
                    <Box sx={{display:'flex', justifyContent:'right'}}>
                        <Box sx={{width:'20%', mb:'2%'}}>
                            <AutoComplete
                                key={tagRerender.toString()}
                                multiple
                                options={tags.map((tag) => tag.name)}
                                onChange={(event, value) => {
                                    setQueryTags(value);
                                }}
                                defaultValue={[]}
                                renderTags={(value: readonly string[]) =>
                                    value.map((option: string, index: number) => (
                                        <Chip key={index} variant="outlined" label={option} />
                                    ))
                                }
                                renderInput={(params:any) => (
                                <TextField
                                    {...params}
                                />
                            )}/>
                        </Box>
                    </Box>
                    <DataGrid
                        rows={cases}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        onRowDoubleClick={(params) => navigate(`/cases/${params.id}`)}
                    />
                    <br />
                    <Button variant='contained' onClick={() => navigate('/create-case')}>Crear Caso</Button>
                </div>
            </div>
        </div>
    )
}

export default Cases;