import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


interface SimulatorGoToProps {
    title: string,
    description: string,
    onClick: () => void
}

const SimulatorGoTo = (props: SimulatorGoToProps) => {
    return (
        <Paper elevation={3} sx={{ display: 'flex', padding: '2.5%', height: '50%', width: '30%' }}>
            <Box sx={{ display:'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between', width:'100%' }}>
                <Box sx={{ height: '50%'}}>
                    <Typography variant='h3' sx={{ fontFamily: 'Playfair Display', color: 'black'}}>
                        {props.title}
                    </Typography>
                    <Typography variant='h6' sx={{ fontFamily: 'Roboto Sans-Serif', color: 'black', mt:'2%'}}>
                        {props.description}
                    </Typography>
                </Box>
                <Button color="secondary" variant='contained' sx={{fontFamily: 'Playfair Display', marginTop: '5%' }}
                        onClick={props.onClick}>
                    Crear
                </Button>
            </Box>
        </Paper>
    )
}

export default SimulatorGoTo;

