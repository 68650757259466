import * as React from 'react'

import ReactGA from 'react-ga4'

import { useNavigate, useParams } from 'react-router-dom'

import ReviewStats from '../components/ReviewStats'

import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Footer from '../components/Footer'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Chip from '@mui/material/Chip'

import useAxios from '../utils/useAxios'

import { PlaylistType, ReviewsSummaryType } from '../types'
import { difficulties, categories, specialties } from '../utils/constants'

const ReviewSummary = () => {
    const navigate = useNavigate()
    const { axios, initialized } = useAxios()

    const { id } = useParams()

    const [playlist, setPlaylist] = React.useState<PlaylistType>({
        id: 0,
        user: 0,
        name: '',
        questions: [],
    })
    const [reviews, setReviews] = React.useState<ReviewsSummaryType[]>([])

    const [edit, setEdit] = React.useState(false);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string)
    }, [])

    React.useEffect(() => {
        const getPlaylist = async () => {
            try {
                const response = await axios.get(`playlists/${id}/`)
                setPlaylist(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        const getReviews = async () => {
            try {
                const response = await axios.get(`reviews/`)
                setReviews(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        if (initialized) {
            getPlaylist()
            getReviews()
        }
    }, [id, initialized])

    const previewQuestion = (prompt: string) => {
        return prompt.length > 15 ? prompt.substring(0, 15) + '...' : prompt
    }

    const handleUpdate = async () => {
        try {
            await axios.put(`/playlists/${id}/`, playlist)
            setEdit(false)
        } catch (error) {
            console.log(error)
        }
    }

    const handleRemove = async (questionId: number) => {
        try {
            await axios.delete('/remove-question-from-playlist/', {
                data: {
                    playlist: playlist.id,
                    question: questionId,
                },
            })
            const response = await axios.get(`/playlists/${id}/`)
            setPlaylist(response.data)
        } catch (error) {   
            console.log(error)
        }
    }

    return (
        <div>
            <section className='review'>
                <Stack>
                <Box sx={{ marginTop: '2%', width: '100%'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <IconButton onClick={() => navigate('/home')}>
                            <ArrowBackIcon  sx={{ fontSize: 36, color: 'white'}}/>
                        </IconButton>
                        {
                            edit ? (
                                <IconButton onClick={() => { setEdit(false); }}>
                                    <SaveIcon onClick={() => handleUpdate()}  sx={{ fontSize: 36, color: 'white'}}/>
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => setEdit(true)}>
                                    <EditIcon  sx={{ fontSize: 36, color: 'white'}}/>
                                </IconButton>
                            )
                        }
                    </Box>
                </Box>
                <Box sx={{ marginTop: '2%'}}>
                    {
                        edit ? (
                            <div>
                                <TextField
                                    id="outlined-basic" 
                                    label="Playlist"
                                    variant="standard"
                                    defaultValue={playlist.name}
                                    onChange={(e) => setPlaylist({...playlist, name: e.target.value})}
                                    sx={{ marginBottom: '2%', fontSize: 48, color: 'white' }}
                                    size="medium"
                                    autoFocus
                                />
                            </div>
                        ) : (
                            <Typography variant='h3' gutterBottom color={"white"} sx={{ fontFamily: 'Playfair Display' }}>
                                {playlist.name}
                            </Typography>
                        )
                    }
                    {
                        playlist.questions.length !== 0 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                                <ReviewStats questions={playlist.questions} />
                            </Box> 
                        ) : null
                    } 
                    <Box sx={{ marginTop: '4%', marginBottom: '4%'}}>
                        <Typography variant='h4' gutterBottom color={"white"} sx={{ fontFamily: 'Playfair Display', display: 'flex', justifyContent: 'left' }}>
                            Preguntas
                        </Typography>
                        {
                            playlist.questions.length === 0 ? (
                                <Typography variant='h6' gutterBottom color={"white"} sx={{ fontFamily: 'Roboto', display: 'flex', justifyContent: 'left' }}>
                                    No hay preguntas en esta playlist. Agrega preguntas desde tus simuladores dando click en la estrella.
                                </Typography>
                            ) : null
                        }
                        <Stack spacing={2}>
                        {
                            playlist.questions.map((question, index) => (
                                <div key={index}>
                                    <Paper sx={{ padding: '2%'}}>
                                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
                                            <Stack direction="row" spacing={12}>
                                                <Typography variant='h5' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                                    {previewQuestion(question.prompt)}
                                                </Typography>
                                                <Chip label={difficulties.map.get(question.case.difficulty)} />
                                                <Chip label={categories.map.get(question.case.category)} />
                                                <Chip label={specialties.map.get(question.case.specialty)} />
                                            </Stack>
                                            <Button 
                                            onClick={() => handleRemove(question.id)}
                                            sx={{ align: 'right', position: 'relative' }}>
                                                Retirar
                                            </Button>
                                        </Box>
                                    </Paper>    
                                </div>
                            ))
                        }
                        </Stack>
                    </Box>
                </Box>
                </Stack>
            </section>
            <Footer />
        </div>
    )
}

export default ReviewSummary;