import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

interface FinalizeExamDialogProps {
    open: boolean;
    handleClose: () => void;
    handleFinalize: () => void;
}

const FinalizeExamDialog = (props: FinalizeExamDialogProps) => {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>¿Estás seguro de que quieres finalizar el examen?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Una vez finalizado el examen no podrás volver a realizarlo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Cancelar</Button>
                    <Button onClick={props.handleFinalize}>Finalizar</Button>
                </DialogActions>
            </Dialog>
    )
}

export default FinalizeExamDialog