import * as React from "react";

import ReactGA from "react-ga4";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";


import { useParams } from "react-router-dom"; 

import AuthContext from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;

const Validating = () => {
    const { uid, token } = useParams<{ uid: string; token: string }>();
    const { logoutUser } = React.useContext(AuthContext);

    const [open, setOpen] = React.useState(false);

    const [backdrop, setBackdrop] = React.useState(true);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    React.useEffect(() => {
        const verify = async () => {
            try {
                const response = await fetch(`${baseURL}users/activate-account/${uid}/${token}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                setBackdrop(false);
                setOpen(true);
            } catch (error) {
                console.log(error);
            }
        };
        verify();
    }, [uid, token]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen py-2">
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    logoutUser();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Cuenta verificada"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tu cuenta ha sido verificada. Ahora puedes iniciar sesión.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            logoutUser();
                        }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop} 
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
};

export default Validating;