
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import TimeRemaining from './TimeRemaining';
import EnarmDefaultNavigator from './EnarmDefault/Navigator';
import EnarmClassicCase from './EnarmClassic/Case';
import EnarmDefaultCase from './EnarmDefault/Case';

import { CaseType } from '../types';

interface MainSimulatorProps {
    classic: boolean
    examData: any
    playlists: any[]
    addPlaylist?: (name: string) => Promise<void>
    addQuestionToPlaylist?: (playlistId: number, questionId: number) => Promise<void>
    removeQuestionFromPlaylist?: (playlistId: number, questionId: number) => Promise<void>
    currentCase: number
    handleChangeCase: (newCase: number) => void
    handleAnswerSelected: ((selectedCase: CaseType, qIndex: number, aIndex: number) => Promise<void>) | ((selectedCase: CaseType, qIndex: number, aIndex: number) => void)
    handleFinishExam: () => void
    markedCases: number[]
    markCase: (caseIndex: number) => void
    unmarkCase: (caseIndex: number) => void
    caseRef: React.RefObject<HTMLDivElement>
    drawerOpen: boolean
    timeElapsed: number
    completedCases: Set<string>
    completedQuestions: Set<string>
}

const MainSimulator = (props: MainSimulatorProps) => {
    return (
        <div>
            <Box sx={{ display:'flex', alignItems:'center', width:'100%', height:'100%'}}>
                <Box sx={{ width:'100%', height:'100%', display:'table'}}>
                    <Box sx={{ display:'table-row', height:'100%', width:'100%', flexGrow:1}}>
                        <Stack sx={{ display:'table-cell', alignItems:'left'}}>
                            {props.classic ?
                                <EnarmClassicCase examData={props.examData}
                                                    playlists={props.playlists}
                                                    addPlaylist={props.addPlaylist}
                                                    addQuestionToPlaylist={props.addQuestionToPlaylist}
                                                    removeQuestionFromPlaylist={props.removeQuestionFromPlaylist}
                                                    handleAnswerSelected={props.handleAnswerSelected} 
                                                    handleFinishExam={props.handleFinishExam}
                                                    markedCases={props.markedCases}
                                                    markCase={props.markCase}
                                                    unmarkCase={props.unmarkCase}/>
                                :
                                <EnarmDefaultCase examData={props.examData} 
                                                    playlists={props.playlists}
                                                    addPlaylist={props.addPlaylist}
                                                    addQuestionToPlaylist={props.addQuestionToPlaylist}
                                                    removeQuestionFromPlaylist={props.removeQuestionFromPlaylist}
                                                    currentCase={props.currentCase} 
                                                    handleChangeCase={props.handleChangeCase} 
                                                    handleAnswerSelected={props.handleAnswerSelected} 
                                                    handleFinishExam={props.handleFinishExam}
                                                    caseRef={props.caseRef}/>
                                }
                        </Stack>

                        {props.classic ?
                            null
                            : 
                            <Slide direction="left" in={props.drawerOpen} mountOnEnter unmountOnExit timeout={500} style={{ display:'table-cell', width:'20%', height:'100%', flexGrow:1}}>
                                <Box>
                                    <Paper elevation={0} sx={{ height:'20vh', mb:'5vh', display:'flex', alignItems:'top', justifyContent:'center'}}>
                                        <Stack sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                            <Typography variant='subtitle1' sx={{ color: 'black', textAlign:'center'}}>
                                                Tiempo restante
                                            </Typography>
                                            <TimeRemaining initialSeconds={props.examData?.timeIntendedMilliseconds/1000} elapsedSeconds={props.timeElapsed} size={120}/>
                                        </Stack>
                                    </Paper>

                                    <EnarmDefaultNavigator examData={props.examData} 
                                                        completedCases={props.completedCases} 
                                                        completedQuestions={props.completedQuestions}
                                                        handleChangeCase={props.handleChangeCase} 
                                                        currentCase={props.currentCase}/>
                                </Box>
                            </Slide>
                        }
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default MainSimulator;