import Gradient from 'rgt';

const Grade = (props) => {
    return (
        <h1>
            <Gradient dir="left-to-right" from={props.from} to={props.to}>
                {props.prompt}
            </Gradient>
        </h1>
    )
}

export default Grade;