import React from 'react';
import 'css-doodle';

const Doodle =  ({ seed }) => {
    //Replace Math.random() with seed
    const purelyNumericSeed = seed.replace(/\D/g,'');
    const seedAsNumber = parseInt(purelyNumericSeed);
    const hex = (seedAsNumber).toString(16).slice(0, 6);
    const doodleRule = `
    @grid: 1 / 8rem  8rem;
    background-size: 83px 135px;
    background-color: #${hex};
    background-image: @doodle(
        @grid: 2 / 100%;
        background: @pn(#3C2B34, #F7F0E9, #F7F0E9);
        transform:
        rotateX(45deg)
        skewY(@pn(34deg, -34deg, -34deg));
    ); `
    return (
        <css-doodle seed={seed}>{ doodleRule }</css-doodle>
    )
}

export default Doodle;