import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

type SimpleKeyValueTableTableProps = {
    data:any,
    headerColor?: string,
    headerTextColor?: string,
}

const SimpleKeyValueTable = (props: SimpleKeyValueTableTableProps) => {
    const data = props.data
    const columns = Object.keys(data);

    return (
        <TableContainer component={Paper} sx={{display:'flex'}}>
            <Table size="small" aria-label="a dense table">
                <TableBody>
                    {columns.map((column: any, index:any) => (
                        <TableRow key={index}>
                            <TableCell sx={{color: props.headerTextColor, backgroundColor: props.headerColor, border: '0.5px solid #eeeeee'}}>
                                <Typography variant='subtitle1'>
                                    {column}
                                </Typography>
                            </TableCell>
                            
                            <TableCell sx={{border:'0.5px solid #eeeeee'}}>
                                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                    {data[column]}
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SimpleKeyValueTable;