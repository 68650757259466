const demo = {
    cases: [
        {
            id: "1",
            prompt: "Recién nacido masculino obtenido por parto hace 1 minuto. Se le realizan los pasos iniciales y se procede a su evaluación médica. Presenta frecuencia cardiaca de 130 lpm, llanto esporádico, tose y estornuda al ser manipulado, cianosis en manos y pies e hipotonía generalizada.",
            explanation: "Hipotonía generalizada 0 pts, acrocianosis 1 pt, respuesta a estímulos 2 pts, esfuerzo respiratorio con llanto 2 pts, FC >100 lpm 2 pts = 7 puntos\nReferencia: Nelson tratado de pediatría, 19 edición. RM Kiegman, BF Stanton, J St. Geme, NF Schor, RE Behrman, Elsevier, 2012.",
            difficulty: "F",
            category: "MF",
            specialty: "P",
            english: false,
            tags: [],
            questions: [
                {
                    id: "1",
                    prompt: "El puntaje de apgar en éste paciente es:",
                    order: 0,
                    flashcard: false,
                    answers: [
                        {
                            id: "1",
                            prompt: "9",
                            correct: false,
                        },
                        {
                            id: "2",
                            prompt: "8",
                            correct: false,
                        },
                        {
                            id: "3",
                            prompt: "7",
                            correct: true,
                        },
                        {
                            id: "4",
                            prompt: "6",
                            correct: false,
                        }
                    ]
                }
            ],
        },
        {
            id: "2",
            prompt: "Mujer de 19 años de edad, nuligesta, sin antecedentes de importancia acude a su centro de salud al módulo de planificación familiar donde decide ponerse un método anticonceptivo. El médico le coloca el DIU con levonorgestrel el cual tiene una alta efectividad.",
            explanation: `Con está pregunta revisaremos indicaciones del uso del DIU mirena o levonorgestrel las cuales son endometriosis, sangrado uterino anormal por hiperplasia endometrial y como anticonceptivo. Como anticonceptivo de emergencia únicamente el DIU de cobre.
            Barbara L. Hoffman, "Williams ginecología /", -:McGraw-Hill Interamericana, 2017`,
            difficulty: "M",
            category: "SP",
            specialty: "GO",
            english: false,
            tags: [],
            questions: [
                {
                    id: "2",
                    prompt: "¿Cuál de las siguientes no es una indicación del DIU Levonogestrel?",
                    order: 0,
                    flashcard: false,
                    answers: [
                        {
                            id: "5",
                            prompt: "endometriosis",
                            correct: false,
                        },
                        {
                            id: "6",
                            prompt: "anticoncepción de emergencia",
                            correct: true,
                        },
                        {
                            id: "7",
                            prompt: "sangrado uterino anormal",
                            correct: false,
                        },
                        {
                            id: "8",
                            prompt: "anticoncepción",
                            correct: false,
                        }
                    ]
                }
            ],
        },
        {
            id: "3",
            prompt: "Mujer de 24 años, sin antecedentes patológicos de importancia, menarca 14 años, IVSA 18, NPS 2, actualmente con embarazo de 12 seg por FUM. Acude a consulta por náuseas, vómito en una ocasión, pirosis y regurgitación. A la EF: TA: 95/75 mmHg, FC: 65 lpm, FR: 17 rpm. AFU: 28 cm. BH: leu 8 mil, hto 45, hb 12, plt 203, glucosa 82, sodio 140, potasio 3.8.",
            explanation: `En la fisiología del embarazo la progesterona relaja el músculo liso lo que genera dilatación del esfínter esofágico inferior así como disminución de la peristalsis, lo que favorece la pirosis, regurgitación y estreñimiento, la consideramos paciente sana ya que sus paraclínicos son normales y lo que está presentando son cambios fisiológicos del embarazo. El tratamiento de la pirosis en el embarazo el tratamiento de elección son los antiácidos si estos no funcionan se puede optar por un IBP.
            Williams Obstetricia, 25e Cunningham F. 25 Ed, 2019, McGraw-Hill.`,
            difficulty: "D",
            category: "MF",
            specialty: "GO",
            english: false,
            tags: [],
            questions: [
                {
                    id: "3",
                    prompt: "¿Cuál es el diagnóstico de la paciente?",
                    order: 0,
                    flashcard: false,
                    answers: [
                        {
                            id: "9",
                            prompt: "paciente sana",
                            correct: true,
                        },
                        {
                            id: "10",
                            prompt: "hiperemesis gravidica",
                            correct: false,
                        },
                        {
                            id: "11",
                            prompt: "embarazo molar",
                            correct: false,
                        },
                        {
                            id: "12",
                            prompt: "reflujo gastroesofágico",
                            correct: false,
                        }
                    ]
                },
                {
                    id: "4",
                    prompt: "¿Cuál es el mecanismo fisiopatológico implicado en el diagnóstico de la paciente?",
                    order: 1,
                    flashcard: false,
                    answers: [
                        {
                            id: "13",
                            prompt: "estimulo placentario",
                            correct: false,
                        },
                        {
                            id: "14",
                            prompt: "hormona coriónica humana",
                            correct: false,
                        },
                        {
                            id: "15",
                            prompt: "disfunción del esfínter esofágico inferior",
                            correct: false,
                        },
                        {
                            id: "16",
                            prompt: "progesterona",
                            correct: true,
                        }
                    ]
                },
                {

                    id: "5",
                    prompt: "Es el tratamiento adecuado:",
                    order: 2,
                    flashcard: false,
                    answers: [
                        {
                            id: "17",
                            prompt: "antihistaminicos, corticoides",
                            correct: false,
                        },
                        {
                            id: "18",
                            prompt: "AMEU",
                            correct: false,
                        },
                        {
                            id: "19",
                            prompt: "Omprazol",
                            correct: false,
                        },
                        {
                            id: "20",
                            prompt: "antiácido",
                            correct: true,
                        }
                    ]
                }
            ],
        },
    ],
    timeIntendedMilliseconds: 60000 * 5,
}

export default demo;