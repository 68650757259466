import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.updateLocale('en', {
weekdays: [
    "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"
],
weekdaysShort: [
    "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
],
weekdaysMin: [
    "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"
],
months: [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
],
monthsShort: [
    "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
]
})

export default dayjs;