import * as React from 'react';

import ReactGA from 'react-ga4';

import Box from '@mui/material/Box';

import CircularProgress from '@mui/material/CircularProgress';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CheckIcon from '@mui/icons-material/Check';
import GridOnIcon from '@mui/icons-material/GridOn';

import SimulatorAppBar from '../components/SimulatorAppBar';
import FinalizeExamDialog from '../components/FinalizeExamDialog';
import SimulatorSpeedDial from '../components/SimulatorSpeedDial';
import MainSimulator from '../components/MainSimulator';


import { CaseType, QuestionType } from '../types';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import useAxios from '../utils/useAxios';

const Simulator = () => {
    const { axios, initialized } = useAxios();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [queryParams, setQueryParams] = useSearchParams();

    const view = queryParams.get('view');

    const [examData, setExamData] = React.useState<any>(null);
    const [playlists, setPlaylists] = React.useState<Array<any>>([]);
    const [markedCases, setMarkedCases] = React.useState<Array<number>>([]);

    const [completedCases, setCompletedCases] = React.useState<Set<string>>(new Set());
    const [completedQuestions, setCompletedQuestions] = React.useState<Set<string>>(new Set());
    const [currentCase, setCurrentCase] = React.useState<number>(0);
    const [timeElapsed, setTimeElapsed] = React.useState<number>(0);

    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(true);
    const [finishDialogOpen, setFinishDialogOpen] = React.useState<boolean>(false);

    const caseRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    React.useEffect(() => {
        const fetchCases = async () => {
            try {
                const response = await axios.get(`exams/${id}/`, {params: { cases: true }});
                const data = response.data[0];
                const timeElapsedSeconds = data.timeElapsedMilliseconds / 1000;
                setTimeElapsed(timeElapsedSeconds+1);

                const newCases = data.cases.map((caseItem: CaseType, index:number) => {
                    let totalQuestions = 0;
                    let answersSelected = 0;
                    caseItem.questions.forEach((question) => {
                        totalQuestions += 1;
                        question.answers.forEach((answer) => {
                            if (answer.selected === undefined) {
                                answer.selected = false;
                            } else {
                                if (answer.selected === true) {
                                    answersSelected += 1;
                                }
                            }
                            
                        })
                        if (isQuestionCompleted(question)) {
                            const newCompletedQuestions = completedQuestions;
                            newCompletedQuestions.add(question.id)
                            setCompletedQuestions(newCompletedQuestions);
                        }
                    })
                    if (answersSelected === totalQuestions) {
                        const newCompletedCases = completedCases;
                        newCompletedCases.add(caseItem.id);
                        setCompletedCases(newCompletedCases);
                    }
                    return caseItem;
                })
                setExamData({...data, cases: newCases});
            } catch (error) {
                console.log(error);
            }
        }       
        const fetchPlaylists = async () => {
            try {
                const response = await axios.get(`playlists/`);
                const data = response.data;
                setPlaylists(data);
            } catch (error) {
                console.log(error);
            }
        }
        if (initialized) {
            fetchCases();
            fetchPlaylists();
        }
    }, [id, initialized]);

    const markCase = (caseIndex: number) => {
        const newMarkedCases = markedCases;
        newMarkedCases.push(caseIndex);
        setMarkedCases(newMarkedCases);
    }

    const unmarkCase = (caseIndex: number) => {
        const newMarkedCases = markedCases.filter((markedCase) => markedCase !== caseIndex);
        setMarkedCases(newMarkedCases);
    }

    const addPlaylist = async (name: string) => {
        try {
            const response = await axios.post("playlists/", {name: name});
            const newPlaylist = response.data;
            if (response.status === 201) {
                setPlaylists([...playlists, newPlaylist]);
            } else {
                console.log("Something went wrong");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addQuestionToPlaylist = async (playlistId: number, questionId: number) => {
        try {
            const response = await axios.post("add-question-to-playlist/", {playlist: playlistId, question: questionId});
            const updatedPlaylist = response.data;
            if (response.status === 201) {
                const newPlaylists = playlists.map((playlist) => {
                    if (playlist.id === updatedPlaylist.id) {
                        return {...playlist, questions: updatedPlaylist.questions};
                    } else {
                        return playlist;
                    }
                })
                setPlaylists(newPlaylists);
            } else {
                console.log("Something went wrong");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const removeQuestionFromPlaylist = async (playlistId: number, questionId: number) => {
        try {
            const response = await axios.delete("remove-question-from-playlist/", {data: {playlist: playlistId, question: questionId}});
            const updatedPlaylist = response.data;
            if (response.status === 200) {
                const newPlaylists = playlists.map((playlist) => {
                    if (playlist.id === updatedPlaylist.id) {
                        return {...playlist, questions: updatedPlaylist.questions};
                    } else {
                        return playlist;
                    }
                })
                setPlaylists(newPlaylists);
            } else {
                console.log("Something went wrong");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const isQuestionCompleted = (QuestionItem: QuestionType) => {
        const answers = QuestionItem.answers;
        return answers.some((answer) => answer.selected);
    }

    const isCaseCompleted = (CaseItem: CaseType) => {
        const questions = CaseItem.questions;
        return questions.every((question) => isQuestionCompleted(question));
    }

    const checkCompletion = (caseItem: CaseType) => {

        const newCompletedQuestions = completedQuestions;
        for (const question of caseItem.questions) {
            if (isQuestionCompleted(question)) {
                newCompletedQuestions.add(question.id)
                setCompletedQuestions(newCompletedQuestions);
            } else {
                newCompletedQuestions.delete(question.id);
                setCompletedQuestions(newCompletedQuestions);
            }
        }

        const newCompletedCases = completedCases
        if (isCaseCompleted(caseItem)) {
            newCompletedCases.add(caseItem.id);
            setCompletedCases(newCompletedCases);
        } else {
            newCompletedCases.delete(caseItem.id);
            setCompletedCases(newCompletedCases);
        }
    }

    const handlePutElapsedTime = async () => {
        const examId = examData._id as any;
        const timeElapsedMilliseconds = timeElapsed * 1000;
        try {
            await axios.put(`exams/${examId["$oid"]}/`, {"timeElapsedMilliseconds":timeElapsedMilliseconds})
        } catch (error) {
            console.log(error);
        }
    }

    const handlePutAnswer = async (caseId:any,  newQuestions:any) => {
        try {
            await axios.put(`exams-cases/${caseId["$oid"]}/`, {"questions":newQuestions})
        } catch (error) {
            console.log(error);
        }
    }
    
    const handleAnswerSelected = async (selectedCase:CaseType, qIndex:number, aIndex:number) => {
        const questions = selectedCase.questions.map((question:any, i:number) => {
            if (i === qIndex) {
                const newAnswers = question.answers.map((answer:any, j:number) => { 
                    if (j === aIndex) {
                        return {...answer, selected: !answer.selected}
                    }
                    else {
                        return {...answer, selected: false}
                    }
                })
                return {...question, answers:newAnswers}
            } else {
                return question
            }
        })
        const newSelectedCase = {...selectedCase, questions: questions}
        const newCases = examData.cases.map((caseItem:CaseType) => {
            if (caseItem.id === newSelectedCase.id) {
                return newSelectedCase
            } else {
                return caseItem
            }
        })

        const caseId = newSelectedCase._id as any
        setExamData({...examData, cases: newCases});
        handlePutElapsedTime();
        handlePutAnswer(caseId, newSelectedCase.questions);
    }

    const handleChangeCase = (newCase:number) => {
        checkCompletion(examData.cases[currentCase])
        setCurrentCase(newCase)
        caseRef.current?.scrollIntoView({behavior: "smooth", block: "start"})
    }

    const handleExitExam = async () => {
        const examId = examData._id as any;
        const timeElapsedMilliseconds = timeElapsed * 1000;
        try {
            await axios.put(`exams/${examId["$oid"]}/`, {"timeElapsedMilliseconds":timeElapsedMilliseconds})
        } catch (error) {
            console.log(error);
        }
        navigate('/home');
    }

    const handleFinishExam = async () => {
        const examId = examData._id as any;
        const timeElapsedMilliseconds = timeElapsed * 1000;
        try {
            await axios.put(`exams/${examId["$oid"]}/`, {"timeElapsedMilliseconds":timeElapsedMilliseconds})
        } catch (error) {
            console.log(error);
        }
        navigate(`/results/${id}`);
        await axios.post(`write-exams-results/${id}/`);
    }

    React.useEffect(() => {
        const timer = setInterval(() => {
            setTimeElapsed(timeElapsed + 1);
            if (examData?.timeIntendedMilliseconds/1000 - timeElapsed <= 0) {
                handleFinishExam();
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [timeElapsed]);

    const defaultActions = [
        { icon: <GridOnIcon />, name: 'Navegar', onClick: () => setDrawerOpen(!drawerOpen) },
        { icon: <ExitToAppIcon />, name: 'Salir', onClick: handleExitExam },
        { icon: <CheckIcon />, name: 'Finalizar', onClick: () => setFinishDialogOpen(true) },
    ]

    const classicActions = [
        { icon: <ExitToAppIcon />, name: 'Salir', onClick: handleExitExam },
        { icon: <CheckIcon />, name: 'Finalizar', onClick: () => setFinishDialogOpen(true) },
    ]


    if (!examData?.cases.length) {
        return (
            <div>
                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:'15%', width:'100%'}}>
                    <CircularProgress />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <SimulatorAppBar
                remainingTime={examData.timeIntendedMilliseconds/1000 - timeElapsed} 
            />
            <FinalizeExamDialog 
                open={finishDialogOpen} 
                handleClose={() => setFinishDialogOpen(false)} 
                handleFinalize={handleFinishExam} 
            />
            <SimulatorSpeedDial 
                actions={view === 'classic' ? classicActions : defaultActions} 
            />

            <MainSimulator
                classic={view === 'classic'}
                examData={examData}
                playlists={playlists}
                addPlaylist={addPlaylist}
                addQuestionToPlaylist={addQuestionToPlaylist}
                removeQuestionFromPlaylist={removeQuestionFromPlaylist}
                currentCase={currentCase}
                handleChangeCase={handleChangeCase}
                handleAnswerSelected={handleAnswerSelected}
                handleFinishExam={handleFinishExam}
                markedCases={markedCases}
                markCase={markCase}
                unmarkCase={unmarkCase}
                caseRef={caseRef}
                drawerOpen={drawerOpen}
                timeElapsed={timeElapsed}
                completedCases={completedCases}
                completedQuestions={completedQuestions}
            />
        </div>
      )
}

export default Simulator