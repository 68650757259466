import React from 'react';

import ReactGA from 'react-ga4';

const TermsOfService = () => {

  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
  }, []);

  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>
        Welcome to [Your E-Learning Website], operated by [Your Company Name]. These Terms of Service outline the rules and regulations for using our subscription-based e-learning platform. By using our services, you agree to comply with these terms.
      </p>

      <h2>1. Subscription Service</h2>
      <p>
        [Your Company Name] offers a subscription-based e-learning service. By subscribing to our service, you agree to the following:
      </p>
      <ul>
        <li><strong>Subscription Plans:</strong> We offer various subscription plans, each with its pricing and features. You are responsible for selecting the plan that best suits your needs.</li>
        <li><strong>Payment:</strong> You will be billed according to the chosen subscription plan. Payment information is securely processed, and you agree to pay all applicable fees.</li>
        <li><strong>Access and Cancellation:</strong> Your subscription provides access to our e-learning content for the duration of your subscription. You may cancel your subscription at any time, and the cancellation will take effect at the end of the current billing cycle.</li>
      </ul>

      <h2>2. User Responsibilities</h2>
      <p>
        When using our service, you agree to the following:
      </p>
      <ul>
        <li><strong>Account Information:</strong> You are responsible for maintaining the accuracy of your account information, including contact details and payment information.</li>
        <li><strong>Use of Content:</strong> You may access and use our e-learning content for personal, non-commercial purposes. Unauthorized distribution, reproduction, or sharing of content is prohibited.</li>
        <li><strong>Prohibited Activities:</strong> You must not engage in activities that may harm the website or violate applicable laws, including hacking, unauthorized access, or the distribution of malware.</li>
      </ul>

      <h2>3. Privacy</h2>
      <p>
        Your use of our service is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using our service, you consent to the practices described in the Privacy Policy.
      </p>

      <h2>4. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to our service for violations of these Terms of Service or for any reason at our discretion.
      </p>

      <h2>5. Contact Us</h2>
      <p>
        If you have questions or concerns about our Terms of Service, please contact us at [Your Contact Email Address].
      </p>
    </div>
  );
};

export default TermsOfService;
