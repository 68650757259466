import * as React from 'react';

import ReactGA from 'react-ga4';

import { useNavigate, useParams } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const PasswordReset = () => {
    const navigate = useNavigate();

    const { uid, token } = useParams<{ uid: string, token: string}>();

    const [password, setPassword] = React.useState<string>('');
    const [password2, setPassword2] = React.useState<string>('');

    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID as string);
    }, []);

    const handlePasswordReset = async () => {
        if (password !== password2) {
            alert('Las contraseñas no coinciden');
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}users/password-reset/${uid}/${token}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password,
                    password2,
                }),
            });
            setLoading(false);
            if (response.status === 200) {
                alert('Contraseña reestablecida con éxito');
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            } else {
                alert('Ha ocurrido un error. Inténtalo con una liga nueva.');
            }
        } catch (error) {
            alert('Ha ocurrido un error');
        }
    }


    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                {
                    loading ? <CircularProgress sx={{ marginTop: '10%'}} /> :
                    <Paper elevation={3} sx={{ padding: '5%', marginTop: '10%'}}>
                        <Typography variant="h5" component="h3">
                            Reestablece tu contraseña
                        </Typography>
                        <Typography component="p">
                            Ingresa tu nueva contraseña.
                        </Typography>
                        <TextField
                            id='password'
                            label='Contraseña'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            id='password2'
                            label='Confirmar contraseña'    
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            type='password'
                            onChange={(e) => setPassword2(e.target.value)}
                        />
                        <Button variant='contained' color='primary' fullWidth onClick={handlePasswordReset}>
                            Reestablecer contraseña
                        </Button>
                    </Paper>
                }
            </Box>
        </div>
    );
}

export default PasswordReset;