import * as React from 'react';

import '../App.css'

import dayjs from '../utils/dayjs';
import { useNavigate } from 'react-router-dom';

import Grade from '../utils/Grade'

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { Virtuoso } from 'react-virtuoso';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Doodle2 from '../utils/Doodle2';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useAxios from '../utils/useAxios'
import NoPlaylists from './svgs/NoPlaylists';

type GradientProps = {
    dir: 'left-to-right' | 'right-to-left' | 'top-to-bottom' | 'bottom-to-top';
    fron: string;
    to: string;
}

interface GoToPlaylistProps {
    playlists: Array<any>;
    reviews: Array<any>;
    loading: boolean;
    createPlaylist: (name: string) => void;
    details: (id: number) => void;
    go: (id: number) => void;
    showDemo: boolean;
}

const GoToPlaylist = (props: GoToPlaylistProps) => {

    const [open, setOpen] = React.useState(false);
    const [showAll, setShowAll] = React.useState(false);
    const [name, setName] = React.useState<string>('');

    return (
        <section className="simulators">
            <div className='simulators-content'>
                <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Crear review</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nombre de tu Review"
                            type="text"
                            fullWidth
                            onChange={(e) => setName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancelar</Button>
                        <Button disabled={!name} onClick={() => {props.createPlaylist(name); setOpen(false);}}>Crear</Button>
                    </DialogActions>
                </Dialog>
                <Stack sx={{ width: '100%' }}>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <Box sx={{ color: 'white', display:'flex', justifyContent:'space-between', width:'72%' }}>
                            <Typography variant='h3' gutterBottom sx={{ fontFamily: 'Playfair Display'}}>
                                Listas de reproducción
                            </Typography>
                            {
                                !props.showDemo &&
                                <Box sx={{display:'flex', alignItems:'center'}}>                            
                                    <Button variant='contained' sx={{fontFamily: 'Playfair Display'}} onClick={() => setOpen(true)}>
                                        Nueva lista de reproducción
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box>
                        {props.loading ? 
                            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', mt:'15%', width:'100%'}}>
                                <CircularProgress />
                            </Box>
                        :
                            <Box>
                                {props.playlists?.length === 0 ?
                                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%', mt: '2%'}}>
                                        <Typography variant='h5' sx={{ fontFamily: 'Roboto Sans-serif', textAlign:'center', color: 'white'}}>
                                            No tienes listas de reproducción.
                                        </Typography>
                                        <Box sx={{ width: '35%', height: '35%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                                            <NoPlaylists />
                                        </Box>
                                    </Box>
                                :
                                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                                    <Virtuoso
                                        style={{ height: '60vh', paddingBottom:'5%', width:'80%', display:'flex', alignItems:'center', justifyContent:'center'}}
                                        data={showAll ? [...props.playlists, {isButton: true, buttonMessage: 'Ver menos', buttonAction: () => setShowAll(false)}]
                                                    : [...props.playlists.slice(0,2), {isButton: true, buttonMessage: 'Ver todos', buttonAction: () => setShowAll(true)}]}
                                        itemContent={(index, playlist:any) => (
                                            playlist.isButton ?
                                                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%', pb:'2%'}}>
                                                    <Button variant='contained' sx={{fontFamily: 'Playfair Display'}} onClick={playlist.buttonAction}>
                                                        {playlist.buttonMessage}
                                                    </Button>

                                                </Box>
                                            :
                                            <Paper key={index} elevation={3} sx={{ width: '80%', height: '25%', marginLeft: '10%', marginRight: 'auto', mt:'2%', mb:'2%', backgroundColor: '#F5F5F5'}}>
                                                <Stack direction="row" spacing={9} sx={{ height: '100%', alignItems: 'center', p:'1%'}}>
                                                    <Box sx={{ marginLeft: '2%' }}>
                                                        <Doodle2 seed={playlist.id}/>
                                                    </Box>
                                                    <Stack sx={{ marginLeft: '2%', width:'50%' }}>
                                                        <Box>
                                                            <Stack direction="row" sx={{ height: '100%', alignItems: 'center'}}>
                                                                <Typography variant='h4' sx={{ fontFamily: 'Playfair Display', marginLeft: '5%', color: 'black'}}>
                                                                    {playlist.name}
                                                                </Typography>
                                                                <Stack sx={{ width: '100%'}}>
                                                                    <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', marginLeft: '5%', color: 'black'}}>
                                                                        Lo has revisado {props.reviews.find(
                                                                            (review:any) => review._id === playlist.id
                                                                        )?.reviews || 0} veces
                                                                    </Typography>
                                                                    <Typography variant='h6' sx={{ fontFamily: 'Playfair Display', marginLeft: '5%', color: 'black'}}>
                                                                        {playlist.questions.length} Preguntas
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                    <Box>
                                                        <Grade prompt={
                                                            ((props.reviews.find(
                                                                (review:any) => review._id === playlist.id
                                                            )?.avgScore || 0) * 100).toFixed(0) + "%"
                                                        }/>
                                                    </Box>
                                                    <Button variant='contained' 
                                                            onClick={() => props.go(playlist.id)}
                                                            sx={{ marginLeft: '5%', marginTop: '5%', marginBottom: '5%', backgroundColor: '#F5F5F5', color: 'black', fontFamily: 'Playfair Display', borderColor: 'black'}}>
                                                        Revisar
                                                    </Button>
                                                    <IconButton sx={{ borderRadius: '50%'}} onClick={() => props.details(playlist.id)}>
                                                        <MoreVertIcon sx={{ marginLeft: '5%', marginTop: '5%', marginBottom: '5%', color: 'black'}}/>
                                                    </IconButton>
                                                </Stack>
                                            </Paper>
                                        )}
                                    />
                                </Box>
                                }
                            </Box>
                        }
                    </Box>
                </Stack>
            </div>
        </section>
    )
}

export default GoToPlaylist;