import { useEffect } from 'react'

import ReactGA from 'react-ga4'

import { Button, Paper, Stack } from '@mui/material'
import Confetti from 'react-confetti'

import { useNavigate } from 'react-router-dom'
import useAxios from '../utils/useAxios'

const Success = () => {
    const { axios, initialized } = useAxios()
    const navigate = useNavigate()

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA4_ID)
    }, []) 
    
    const handleCreatePortalSession = async () => {
        await axios.post('/create-portal-session/')
            .then((response) => {
                window.location.href = response.data.url;
            }
        );
    }
    
    return (
        <div>
        <Confetti
            width={window.width}
            height={window.height}
        />
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <h1>¡Éxito!</h1>
            <p>Gracias por tu compra.</p>

            <Stack direction="row" spacing={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCreatePortalSession}
                >
                    Ir al portal
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate('/')}
                >
                    Volver a inicio
                </Button>
            </Stack>
        </Paper>
        </div>
    )
}

export default Success 